import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';
import { useNavigate } from 'react-router-dom';

type propTypes = {
  backTo?: string;
};

const BackButton: FC<propTypes> = ({ backTo }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (backTo) {
      navigate(backTo);
    } else {
      navigate(-1);
    }
  };

  return (
    <div className="row mb-3">
      <button onClick={handleClick} className="btn btn-bordered-white">
        {intl.translate({ id: 'Back' })}
      </button>
    </div>
  );
};

export default BackButton;
