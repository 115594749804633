/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  addLoyaltyPointsForNftPostPurchase,
  getLoyaltyProgramForNft,
  getNftsForAddress,
} from 'blockloyal-library';
import NftCustomerCard from 'components/NftCard/NftCustomerCard/NftCustomerCard';
import { Align } from 'layout';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { NftMetadata } from 'types/nft-props';
import ActionModal from 'design/Modal/ActionModal';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledAlign = styled(Align)`
  width: 1200px;
  margin-top: 50px;
`;

const StyledItemsDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 1170px;
  }
  max-height: 600px;
  overflow: scroll;
`;

const TestLibrary: FC = () => {
  // library responses
  const [nfts, setNfts] = useState<NftMetadata[]>();
  const [nftWithPointsUpdated, setNftWithPointsUpdated] = useState<any>();
  const [loyaltyProgramFromNft, setLoyaltyProgramFromNft] = useState<any>();

  // user inputs
  const [wallet, setWallet] = useState<string>('');
  const [nftId, setNftId] = useState<string>('');
  const [usdSpent, setUsdSpent] = useState<number>(0);
  const [loyaltyProgramNftId, setLoyaltyProgramNftId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleGetNftsForAddress = async () => {
    try {
      setLoading(true);
      setNfts(await getNftsForAddress(wallet));
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleGetLoyaltyProgramForNft = async () => {
    try {
      setLoading(true);
      setLoyaltyProgramFromNft(
        await getLoyaltyProgramForNft(loyaltyProgramNftId),
      );
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddLoyaltyPointsForNftPostPurchase = async () => {
    try {
      setLoading(true);
      setNftWithPointsUpdated(
        await addLoyaltyPointsForNftPostPurchase(nftId, usdSpent),
      );
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledAlign h-center v-center>
      <section className="author-area p-0">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-12">
              {/* Intro */}
              <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                <div className="intro-content">
                  <span>Test library</span>
                  <h3 className="mt-3 mb-0">Get NFTs by wallet</h3>
                </div>
              </div>

              <form
                onSubmit={undefined}
                noValidate
                autoComplete="off"
                className="item-form card no-hover"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(event) => {
                          setWallet(event.target.value);
                        }}
                        value={wallet}
                        className="form-control"
                        placeholder="User wallet to get associated blockloyal NFTs"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      onClick={handleGetNftsForAddress}
                      type="button"
                      className="d-block btn btn-bordered-white btn-smaller mt-2 text-white w-100"
                      disabled={loading}
                    >
                      {loading ? 'Wait...' : 'submit'}
                    </button>
                  </div>
                  <div className="col-12 mt-5 ">
                    <span>Total nfts: {nfts?.length}</span>
                    <StyledItemsDiv className="row items">
                      {nfts?.map((nft: NftMetadata, idx: number) => {
                        return <NftCustomerCard {...nft} key={`nft_${idx}`} />;
                      })}
                    </StyledItemsDiv>
                  </div>
                </div>
              </form>

              <div className="intro mt-5 mb-4 mb-lg-5">
                <div className="intro-content">
                  <h3 className="mt-3 mb-0">Get LP from NFT</h3>
                </div>
              </div>

              <form
                onSubmit={undefined}
                noValidate
                autoComplete="off"
                className="item-form card no-hover mt-5"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(event) => {
                          setLoyaltyProgramNftId(event.target.value);
                        }}
                        value={loyaltyProgramNftId}
                        className="form-control"
                        placeholder="NFT id for LP"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      onClick={handleGetLoyaltyProgramForNft}
                      type="button"
                      className="d-block btn btn-bordered-white btn-smaller mt-2 text-white w-100"
                      disabled={loading}
                    >
                      {loading ? 'Wait...' : 'submit'}
                    </button>
                  </div>
                  <div className="col-12 mt-5 ">
                    <p>Loyalty program name: {loyaltyProgramFromNft?.name}</p>
                    <p>levels: {loyaltyProgramFromNft?.levels?.length}</p>
                  </div>
                </div>
              </form>

              <div className="intro mt-5 mb-4 mb-lg-5">
                <div className="intro-content">
                  <h3 className="mt-3 mb-0">Add points on NFT LP</h3>
                </div>
              </div>

              <form
                onSubmit={undefined}
                noValidate
                autoComplete="off"
                className="item-form card no-hover mt-5"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(event) => {
                          setNftId(event.target.value);
                        }}
                        value={nftId}
                        className="form-control"
                        placeholder="NFT id"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={(event) => {
                          setUsdSpent(parseInt(event.target.value));
                        }}
                        value={usdSpent}
                        className="form-control"
                        placeholder="USD Spent"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      onClick={handleAddLoyaltyPointsForNftPostPurchase}
                      type="button"
                      className="d-block btn btn-bordered-white btn-smaller mt-2 text-white w-100"
                      disabled={loading}
                    >
                      {loading ? 'Wait...' : 'submit'}
                    </button>
                  </div>
                  <div className="col-12 mt-5 ">
                    <p>
                      Loyalty program name: {nftWithPointsUpdated?.description}
                    </p>
                    <p>
                      loyaltyPointsGenerated:{' '}
                      {nftWithPointsUpdated?.loyaltyPointsGenerated}
                    </p>
                    <p>name: {nftWithPointsUpdated?.name}</p>
                    <p>tokenCopies: {nftWithPointsUpdated?.tokenCopies}</p>
                    <p>tokenId: {nftWithPointsUpdated?.tokenId}</p>
                    <p>
                      currentLevel:{' '}
                      {nftWithPointsUpdated?.attributes?.currentLevel}
                    </p>
                    <p>
                      pointsRequiredForNextLevel:{' '}
                      {
                        nftWithPointsUpdated?.attributes
                          ?.pointsRequiredForNextLevel
                      }
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ActionModal
          title={intl.translate({
            id: 'Error',
          })}
          open={openErrorModal}
          setOpen={setOpenErrorModal}
          onConfirm={() => null}
          description={errorMessage}
          okButtonText="OK"
          noCancelButton={true}
        />
      </section>
    </StyledAlign>
  );
};

export default TestLibrary;
