/* eslint-disable @typescript-eslint/no-explicit-any */
import useWallet from 'hooks/useWallet';
import WAValidator from 'multicoin-address-validator';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledNumberInput = styled.input`
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

type WalletDto = {
  wallet: string;
  isValidWallet: boolean;
  copies: number;
};

const formDataWalletEmptyDefault: WalletDto = {
  wallet: '',
  isValidWallet: true,
  copies: 0,
};

const formDataWalletsDefault: WalletDto[] = [{ ...formDataWalletEmptyDefault }];

type Props = {
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  nftId: string | undefined;
  vendorAddress: string | undefined;
};

const ManualTransferForm: FC<Props> = ({
  setOpenErrorModal,
  setErrorMessage,
  setOpenSuccessModal,
  nftId,
  vendorAddress,
}: Props) => {
  const { transferNftBatch } = useWallet();
  const [formData, setFormData] = useState<WalletDto[]>(formDataWalletsDefault);
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);

  const addLevels = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setFormData([...formData, { ...formDataWalletEmptyDefault }]);
  };

  const deleteLevel = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const data = [...formData];
    if (data.length === 1) {
      return;
    }
    data.splice(index, 1);
    setFormData(data);
  };

  const handleInputChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const data = [...formData];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data[index] as any)[event.target.name] = event.target.value;
    setFormData(data);
  };

  const getPropertiesAsArray = (object: any, property: string): string[] => {
    try {
      return Object.keys(object).map((res: any) => object[res][property]);
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    }
    return [];
  };

  const getRequestValues = () => {
    const walletsArray: string[] = [];
    const copiesArray: string[] = [];

    walletsArray.push(...getPropertiesAsArray(formData, 'wallet'));
    copiesArray.push(...getPropertiesAsArray(formData, 'copies'));

    return { walletsArray, copiesArray: copiesArray.map((copy) => +copy) };
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;
    setValidForm(true);

    formData.forEach((input, index: number) => {
      if (!input.wallet || !WAValidator.validate(input.wallet, 'ETH')) {
        setFormData((previousValue) => {
          previousValue[index].isValidWallet = false;
          return previousValue;
        });
        setValidForm(false);
        valid = false;
      } else {
        setFormData((previousValue) => {
          previousValue[index].isValidWallet = true;
          return previousValue;
        });
      }
      if (!input.copies) {
        setValidForm(false);
        valid = false;
      }
    });

    if (!valid) {
      return;
    }

    setLoading(true);

    try {
      const { walletsArray, copiesArray } = getRequestValues();
      if (vendorAddress && nftId) {
        await transferNftBatch(vendorAddress, nftId, walletsArray, copiesArray);
      } else {
        throw new Error('No vendor or NFT was found');
      }

      setOpenSuccessModal(true);
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setFormData([{ ...formDataWalletEmptyDefault }]);
  }, []);

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className="card item-form no-hover"
    >
      {formData.map((input, index) => {
        return (
          <div key={index} className="row">
            <div className="col-12 col-md-6">
              <div className="form-group mt-3">
                <input
                  type="text"
                  className={`form-control ${
                    (!validForm && !formData[index].wallet) ||
                    !formData[index].isValidWallet
                      ? 'is-invalid'
                      : ''
                  }`}
                  placeholder={intl.translate({
                    id: 'Wallet',
                  })}
                  required
                  name="wallet"
                  value={input.wallet}
                  onChange={(event) => handleInputChange(index, event)}
                  disabled={loading}
                />
                <div
                  id="validationServer03Feedback"
                  className="invalid-feedback"
                >
                  {intl.translate({
                    id: 'Please provide a valid Wallet.',
                  })}
                </div>
              </div>
            </div>
            <div
              className={`col-12 ${
                formData.length === 1 ? 'col-md-6' : 'col-md-3'
              }`}
            >
              <div className="form-group mt-3">
                <StyledNumberInput
                  type="number"
                  className={`form-control ${
                    !validForm && !formData[index].copies ? 'is-invalid' : ''
                  }`}
                  placeholder={intl.translate({
                    id: 'Copies to transfer',
                  })}
                  required
                  name="copies"
                  value={input.copies}
                  onChange={(event) => handleInputChange(index, event)}
                  disabled={loading}
                  maxLength={30}
                />
                <div
                  id="validationServer03Feedback"
                  className="invalid-feedback"
                >
                  {intl.translate({
                    id: 'Please provide a valid amount of copies.',
                  })}
                </div>
              </div>
            </div>
            {formData.length > 1 && (
              <div className="col-12 col-md-3">
                <button
                  style={{ backgroundImage: 'none' }}
                  className="btn btn-danger mt-3 float-right"
                  disabled={loading}
                  onClick={(event) => deleteLevel(index, event)}
                  tabIndex={-1}
                >
                  {intl.translate({ id: 'Remove wallet' })}
                </button>
              </div>
            )}

            {formData.length === index + 1 && (
              <div className="col-12">
                <button
                  style={{ backgroundImage: 'none' }}
                  className="btn btn-primary mt-3 mt-sm-4 float-right"
                  disabled={loading}
                  onClick={addLevels}
                >
                  {intl.translate({ id: 'Add new wallet' })}
                </button>
              </div>
            )}
          </div>
        );
      })}

      <div className="col-12">
        <button
          className="btn w-100 mt-3 mt-sm-4"
          type="submit"
          disabled={loading}
        >
          {loading
            ? intl.translate({
                id: 'Wait...',
              })
            : intl.translate({
                id: 'Transfer',
              })}
        </button>
      </div>
    </form>
  );
};

export default ManualTransferForm;
