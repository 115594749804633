import { getNonce, userLogIn, userLogOut } from 'components/Auth/Auth.provider';
import { mainChainId } from 'config/blockchain.config';
import { useCallback, useState } from 'react';
import { logger } from 'utilities/logger/Logger';
import {
  useAccount,
  useDisconnect,
  useNetwork,
  useSignMessage,
  useSwitchNetwork,
} from 'wagmi';

const useAuth = () => {
  const [connecting, setConnecting] = useState<boolean>(false);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();

  const { signMessage } = useSignMessage({
    message: '',
    onSuccess(signature) {
      userLogIn({
        address,
        signature,
      });
      logger.info('Connected', { address });
      setConnecting(false);
    },
    onError() {
      disconnect();
    },
  });

  const switchToNetworkIfNeeded = useCallback(async () => {
    if (chain?.id !== mainChainId) {
      await switchNetworkAsync?.(mainChainId);
    }
  }, [chain?.id, switchNetworkAsync]);

  const login = useCallback(async () => {
    try {
      setConnecting(true);
      if (address) {
        await switchToNetworkIfNeeded();
        const userNonce = await getNonce({ address });
        signMessage({ message: userNonce });
      } else {
        throw new Error('login error');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.code !== 4001) {
        alert(err);
      }
      logger.error(err);
      userLogOut();
      setConnecting(false);
    }
  }, [address, signMessage, switchToNetworkIfNeeded]);

  const logout = () => {
    userLogOut();
  };

  return { login, connecting, logout };
};

export default useAuth;
