import { AppRoute } from 'enums/app-route.enum';
import { NftStatusEnum } from 'enums/nft.enum';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nft } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledContainerDiv = styled.div``;

const StyledNftImg = styled.img`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  height: fit-content;
`;

const StyledSpan = styled.span`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
`;

const StyledImgContainerDiv = styled.div`
  height: 0px;
  padding-bottom: 66.6667%;
  position: relative;
`;

const StyledTitleH5 = styled.h5`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const NftCard: FC<Nft> = (nft) => {
  return (
    <StyledContainerDiv
      className="col-12 col-sm-6 col-lg-3 item"
      title={nft.title}
    >
      <Link className="card" to={`${AppRoute.Admin}${AppRoute.NFT}/${nft.id}`}>
        <StyledImgContainerDiv className="image-over">
          <StyledSpan>
            <StyledNftImg className="card-img-top" src={nft.image} alt="" />
          </StyledSpan>
        </StyledImgContainerDiv>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          {/* Card Body */}
          <div className="card-body">
            <StyledTitleH5 className="mb-0">{nft.title}</StyledTitleH5>
            <div className="seller d-flex align-items-center my-3 justify-content-between">
              <span className="text-white">
                {intl.translate({ id: 'Amount of copies' })}
              </span>
              <h6 className="ml-2 mb-0">{nft.tokenCopies}</h6>
            </div>
            <div className="seller d-flex align-items-center my-3 justify-content-between">
              <span className="text-white">
                {intl.translate({ id: 'Loyalty name' })}
              </span>
              <h6 className="ml-2 mb-0">
                {nft.loyaltyProgram?.name ? nft.loyaltyProgram.name : '-'}
              </h6>
              {/* </a> */}
            </div>
            <div className="seller d-flex align-items-center my-3 justify-content-between">
              <span className="text-white">
                {intl.translate({ id: 'Loyalty levels' })}
              </span>
              <h6 className="ml-2 mb-0">
                {nft.loyaltyProgram?.levels?.length
                  ? nft.loyaltyProgram?.levels?.length
                  : '-'}
              </h6>
            </div>
            <div className="seller d-flex align-items-center justify-content-center mt-3">
              <span className="mb-0 text-white">
                {nft.status === NftStatusEnum.Draft ? 'Draft' : 'Minted'}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </StyledContainerDiv>
  );
};

export default NftCard;
