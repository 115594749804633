/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from 'design';
import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const PrivacyPolicy: FC = () => {
  return (
    <div className="container">
      <Text.h2>{intl.translate({ id: 'Privacy Policy' })}</Text.h2>

      <p>
        {`Blockloyal is committed to protecting the privacy of our customers. We
        do not collect any personal information from our customers. The
        information we do collect, is related to purchase data, which we use to
        provide rewards through our loyalty program marketing strategy. We do
        not sell, trade, or transfer any customer information to outside
        parties. We use a secure third-party loyalty program provider to process
        all transactions and manage our loyalty program reward system. We do not
        store any payment information on our servers and we use cookies to
        improve the user experience on our website and for tracking website
        analytics. These cookies are just small data files that are stored on
        your own personal device when visiting our website. We do not use
        cookies to collect personal information or tracking browsing activity
        outside of our website. We take the respective measures to protect the
        security of our customer's information, collected through our loyalty
        program strategy. This includes using industry-standard encryption and
        security technologies to protect against unauthorized access,
        disclosure, or destruction of customer information. If you have any
        questions or concerns about our privacy policy, please contact us at `}
        <a href="mailto:bob@blockloyal.com" className="text-primary">
          bob@blockloyal.com
        </a>
        .
      </p>
    </div>
  );
};

export default PrivacyPolicy;
