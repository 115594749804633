const Docs = () => {
  return (
    <div id="docs" className="container">
      <h2>Blockloyal Tokengating Shopify App</h2>
      <p>
        Welcome to the Blockloyal Tokengating documentation for Shopify. This
        guide will walk you through the steps to install and use the app.
      </p>
      <h3>Installation</h3>
      <p>To install the app, follow these steps:</p>
      <ul>
        <li>{'Log in to your Shopify account'}</li>
        <li>{'Navigate to the App Store'}</li>
        <li>
          {
            'Search for "Blockloyal Tokengating" and click on the app to view the details'
          }
        </li>
        <li>
          {
            'Click "Add App" and follow the prompts to complete the installation'
          }
        </li>
      </ul>
      <h3>Using the App</h3>
      <p>
        {`Once the app is installed, you can access it from the Shopify dashboard.
        Here's an example component that you can use in your app:`}
      </p>
      <ul>
        <li>Log in with your address and password given by Blockloyal</li>
        <li>{`Click on "Configure Tokens"`}</li>
        <li>{`Select the required NFT of your vendor collection`}</li>
        <li>{`Configure access or discount for the products you want to`}</li>
        <li>{`Save your tokengate`}</li>
      </ul>

      <h3>How to configure access and discounts</h3>
      <p>
        {`In order to create gated-pages and apply discounts you need some configurations before:`}
      </p>
      <h4>Access</h4>
      <p>
        {`For the gated-pages feature it's needed a page with the same ID as the configured in the tokengating form.`}
      </p>
      <ul>
        <li>{`Go to Pages`}</li>
        <li>{`Click on "Add page"`}</li>
        <li>{`On content click "Show HTML"`}</li>
        <li>{`Add your HTML code and make sure to add the same ID as added before`}</li>
        <li>{`Save your new page and add it to your store going to Navigation menus`}</li>
      </ul>
      <a
        href="https://share.vidyard.com/watch/VhvprXm5m6P3uLbGfACK1A"
        target="_blank"
        rel="noreferrer"
      >
        Access Demo
      </a>
      <h4>Discounts</h4>
      <p>
        {`A block needs to be added on the product template of our theme to see the connect wallet button.`}
      </p>
      <ul>
        <li>{`Go to Theme`}</li>
        <li>{`Click on customize`}</li>
        <li>{`Select Products page template`}</li>
        <li>{`Add "Tokengate" block from Blockloyal Tokegating`}</li>
        <li>{`Save your changes`}</li>
      </ul>
      <a
        href="https://share.vidyard.com/watch/WYBgNvLRp3478TWL4J4p1R"
        target="_blank"
        rel="noreferrer"
      >
        Discounts Demo
      </a>

      <h3>Additional Resources</h3>
      <p>
        For more information on using the Shopify App, check out the following
        resources:
      </p>
      <ul>
        <li>
          <a
            href="https://help.shopify.com/en/manual/apps"
            target="_blank"
            rel="noreferrer"
          >
            Shopify App documentation
          </a>
        </li>
        <li>
          <a
            href="https://community.shopify.com/c/shopify-community/ct-p/en"
            target="_blank"
            rel="noreferrer"
          >
            Shopify Community forums
          </a>
        </li>
        <li>
          <a
            href="https://share.vidyard.com/watch/SiU1sHYCraBbKgPNqtkPyS?"
            target="_blank"
            rel="noreferrer"
          >
            Full demo
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Docs;
