import { getVendorApi, listNftModelAdminApi } from 'api/admin.api';
import BackButton from 'components/Common/BackButton/BackButton';
import Loading from 'components/Common/Loading/Loading';
import NftCard from 'components/NftCard/NftCard';
import { AppRoute } from 'enums/app-route.enum';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Nft } from 'types/nft-props';
import { User } from 'types/users-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledItemsDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 1170px;
  }
`;

const StyledSection = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: 47rem;
  }
`;

const AdminListNftView: FC = () => {
  const { vendorId } = useParams();
  const [nfts, setNfts] = useState<Nft[]>([]);
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState<User | null>(null);

  useEffect(() => {
    const getNfts = async () => {
      setLoading(true);

      if (vendorId) {
        const vendor = await getVendorApi(vendorId);
        setVendor(vendor);
        const returnNfts = await listNftModelAdminApi(vendorId);
        setNfts(returnNfts);
      }

      setLoading(false);
    };

    getNfts();
  }, [vendorId]);

  return (
    <>
      <StyledSection className="explore-area p-0">
        <div className="container">
          <BackButton backTo={AppRoute.Admin} />
          <div className="row">
            <div className="col-12">
              <div className="intro d-flex justify-content-between align-items-end m-0">
                <div className="intro-content">
                  <span>{intl.translate({ id: 'Admin' })}</span>
                  <h3 className="mt-3 mb-0">
                    {intl.translate({ id: "List of vendor's NFTs" })}:{' '}
                    {vendor?.nickName}
                  </h3>
                </div>
                <div className="intro-btn">
                  <Link
                    className="btn content-btn"
                    to={`${AppRoute.AdminCreateNFT}/${vendorId}`}
                  >
                    {intl.translate({ id: 'Create Token' })}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <StyledItemsDiv className="row items">
            {loading ? (
              <Loading />
            ) : nfts.length === 0 ? (
              <div className="container text-center">
                {intl.translate({ id: 'No data' })}
              </div>
            ) : (
              nfts.map((nft: Nft, idx) => {
                return <NftCard {...nft} key={`nft_${idx}`} />;
              })
            )}
          </StyledItemsDiv>
        </div>
      </StyledSection>
    </>
  );
};

export default AdminListNftView;
