/* eslint-disable @typescript-eslint/no-explicit-any */
import { authProvider, isAuthenticated } from 'components/Auth/Auth.provider';
import Collections from 'components/Collections/Collections';
import Hero from 'components/Hero/Hero';
import { AppRoute } from 'enums/app-route.enum';
import { FC, useEffect } from 'react';
import { useBranchState } from 'react-branch-provider';
import { useNavigate } from 'react-router';

const LoginView: FC = () => {
  const state = useBranchState(authProvider);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(AppRoute.Home, { replace: true });
    }
  }, [state, navigate]);

  return (
    <>
      <Hero />
      <Collections />
    </>
  );
};

export default LoginView;
