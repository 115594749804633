import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { Network } from 'alchemy-sdk';
import { config } from 'config';
import { alchemyNetwork } from 'config/alchemy.config';
import { chain, configureChains, createClient } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';

const chainArray =
  alchemyNetwork === Network.MATIC_MUMBAI
    ? [chain.polygonMumbai]
    : [chain.polygon];

export const { chains, provider } = configureChains(chainArray, [
  alchemyProvider({ apiKey: config.alchemy.alchemyApiKey }),
  publicProvider(),
]);

const { connectors } = getDefaultWallets({
  appName: 'BlockLoyal',
  chains,
});

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});
