import NftCustomerCard from 'components/NftCard/NftCustomerCard/NftCustomerCard';
import useWallet from 'hooks/useWallet';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NftMetadata } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledItemsDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 1170px;
  }
`;

const StyledSection = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: 47rem;
  }
`;

const CustomerListNftView = () => {
  const { getNftsForOwner } = useWallet();
  const [nfts, setNfts] = useState<NftMetadata[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getNfts = async () => {
      try {
        setLoading(true);
        setNfts(await getNftsForOwner());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    getNfts();
  }, [getNftsForOwner]);

  return (
    <StyledSection className="explore-area p-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="intro d-flex justify-content-between align-items-end m-0">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Customer' })}</span>
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'My NFTs' })}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <StyledItemsDiv className="row items">
          {loading ? (
            <div className="container text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">
                  {intl.translate({ id: 'Loading...' })}
                </span>
              </div>
            </div>
          ) : !nfts.length ? (
            <div className="container text-center">
              <h4>{intl.translate({ id: "You don't have any NFT yet..." })}</h4>
              <a
                className="btn ml-lg-auto btn-bordered-white"
                href="https://opensea.io/"
                target="_blank"
                rel="noreferrer"
              >
                {intl.translate({ id: 'Buy' })}
              </a>
            </div>
          ) : (
            nfts.map((nft: NftMetadata, idx: number) => {
              return <NftCustomerCard {...nft} key={`nft_${idx}`} />;
            })
          )}
        </StyledItemsDiv>
      </div>
    </StyledSection>
  );
};

export default CustomerListNftView;
