import { deleteLoyaltyProgram, getNftVendorApi } from 'api/vendor.api';
import BackButton from 'components/Common/BackButton/BackButton';
import Loading from 'components/Common/Loading/Loading';
import NoNFT from 'components/NoNFT/NoNFT';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import { NftStatusEnum } from 'enums/nft.enum';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Nft } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledAlign = styled(Align)`
  height: 100%;
  width: 100vw;
`;

const VendorNFTDetailedView: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [nft, setNft] = useState<Nft>();
  const [loading, setLoading] = useState<boolean>(true);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');

  const onClickDeleteLp = async (id: string) => {
    setSelectedId(id);
    setConfirmOpen(true);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteLoyaltyProgram(selectedId);
      navigate(`${AppRoute.Home}`, { replace: true });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
  };

  useEffect(() => {
    const getNft = async () => {
      try {
        if (id) {
          setLoading(true);
          const nft = await getNftVendorApi(id);
          if (nft) {
            setNft(nft);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger.error(error);
      } finally {
        setLoading(false);
      }
    };

    getNft();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (!nft) {
    return <NoNFT backTo={`${AppRoute.Home}`} />;
  }

  return (
    <StyledAlign v-center h-center>
      <section className="item-details-area pt-3">
        <div className="container">
          <BackButton />
          <div className="row justify-content-between mt-5">
            {/* 1st Row */}
            <div className="col-12 col-lg-5">
              <div className="item-info">
                <div className="item-thumb text-center">
                  <img src={nft.image} alt="" />
                </div>
              </div>
            </div>
            {/* 2nd Row */}
            <div className="col-12 col-lg-6">
              {/* NFT Content */}
              <div className="content mt-5 mt-lg-0">
                <h3 className="m-0">{nft.title}</h3>
                <p>{nft.description}</p>
                <div className="item-info-list mt-4">
                  <ul className="list-unstyled">
                    <li className="price d-flex justify-content-between">
                      <span>{intl.translate({ id: 'Number of copies:' })}</span>
                      <span>{nft.tokenCopies}</span>
                    </li>
                    <li className="price d-flex justify-content-between">
                      <span>{intl.translate({ id: 'Token ID Range' })}</span>
                      <span>
                        {nft.tokenRangeFrom} - {nft.tokenRangeTo}
                      </span>
                    </li>
                    <li className="price d-flex justify-content-between">
                      <span>{intl.translate({ id: 'Created by:' })}</span>
                      <span>
                        {nft.vendorOwner.nickName} -{' '}
                        {`${nft.vendorOwner?.walletAddress?.substring(
                          0,
                          4,
                        )}...${nft.vendorOwner?.walletAddress?.substring(
                          38,
                          nft.vendorOwner?.walletAddress?.length,
                        )}`}
                      </span>
                    </li>
                  </ul>
                </div>

                {/* Action Buttons */}
                {nft.loyaltyProgram ? (
                  <div className="card-bottom d-flex justify-content-between">
                    <Link
                      to={`${AppRoute.EditLoyaltyProgram}/${nft.loyaltyProgram.id}`}
                      className="d-block btn btn-bordered-white btn-smaller mt-4 text-white w-100 mr-2"
                    >
                      {intl.translate({ id: 'Edit LP' })}
                    </Link>
                    <button
                      onClick={() => {
                        if (nft.loyaltyProgram.id) {
                          onClickDeleteLp(nft.loyaltyProgram.id);
                        }
                      }}
                      // setDeletedId(nft.loyaltyProgram.id)
                      type="button"
                      className="d-block btn btn-bordered-white btn-smaller mt-4 text-white w-100 ml-2"
                    >
                      {intl.translate({ id: 'Delete LP' })}
                    </button>
                  </div>
                ) : (
                  <Link
                    to={`${AppRoute.CreateLoyaltyProgram}/${nft.id}`}
                    className="d-block btn btn-bordered-white btn-smaller mt-4 text-white w-100"
                  >
                    {intl.translate({ id: 'Create LP' })}
                  </Link>
                )}
                {nft.status === NftStatusEnum.Minted ? (
                  <div className="card-bottom d-flex justify-content-between">
                    <Link
                      to={`${AppRoute.Vendor}${AppRoute.TransferMultiple}/${nft.id}`}
                      className="d-block btn btn-bordered-white btn-smaller mt-4 text-white w-100"
                    >
                      {intl.translate({ id: 'Transfer' })}
                    </Link>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <ActionModal
          title={intl.translate({
            id: 'Delete Loyalty Program',
          })}
          open={confirmOpen}
          setOpen={setConfirmOpen}
          onConfirm={onConfirmDelete}
          description={intl.translate({
            id: 'Are you sure? Please confirm',
          })}
          okButtonText="Confirm"
        />
      </section>
    </StyledAlign>
  );
};

export default VendorNFTDetailedView;
