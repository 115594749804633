import { editLoyaltyProgram, getLoyaltyProgram } from 'api/vendor.api';
import BackButton from 'components/Common/BackButton/BackButton';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Level, LoyaltyProgram } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledNumberInput = styled.input`
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const formDataLevelEmptyDefault: Level = {
  ecommerceCupon: '',
  cuponDescription: '',
  requiredPointsGetLevel: 0,
  pointsPerDollar: 0,
  exclusivePageCode: '',
};
const formDataLevelsDefault: Level[] = [{ ...formDataLevelEmptyDefault }];
const formDataDefault: LoyaltyProgram = {
  name: '',
  levels: formDataLevelsDefault,
};

const invalidNumberInputSymbols = ['e', 'E', '+', '-', '.', ','];

const EditLoyaltyProgramView: FC = () => {
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loyaltyProgram, setLoyaltyProgram] = useState({ ...formDataDefault });
  const [formData, setFormData] = useState(formDataDefault);
  const [formDataLevels, setFormDataLevels] = useState(formDataLevelsDefault);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const loadLoyaltyProgram = async () => {
      setLoading(true);
      const nftIdNotNull = id ? id : '';
      const loyaltyProgram = await getLoyaltyProgram(nftIdNotNull);
      setLoyaltyProgram(loyaltyProgram);
      setLoading(false);
    };

    loadLoyaltyProgram();
  }, [id]);

  useEffect(() => {
    if (loyaltyProgram) {
      setFormData((values) => ({
        ...values,
        name: loyaltyProgram.name,
      }));

      const levels = [];

      for (const level of loyaltyProgram.levels) {
        const levelToAdd = {
          ecommerceCupon: level.ecommerceCupon,
          cuponDescription: level.cuponDescription,
          requiredPointsGetLevel: level.requiredPointsGetLevel,
          pointsPerDollar: level.pointsPerDollar,
          exclusivePageCode: level.exclusivePageCode,
        };

        levels.push(levelToAdd);
      }

      setFormDataLevels(levels);
    }
  }, [loyaltyProgram]);

  const handleNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();
    setFormData((values) => ({
      ...values,
      name: event.target.value,
    }));
  };

  const handleInputLevelChange = (
    index: number,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const data = [...formDataLevels];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data[index] as any)[event.target.name] = event.target.value;
    setFormDataLevels(data);
  };

  const addLevels = (e: React.MouseEvent) => {
    e.preventDefault();
    setFormDataLevels([...formDataLevels, { ...formDataLevelEmptyDefault }]);
  };

  const deleteLevel = (index: number, event: React.MouseEvent) => {
    event.preventDefault();
    const data = [...formDataLevels];
    data.splice(index, 1);
    setFormDataLevels(data);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let valid = true;

    if (!formData.name) {
      setValidForm(false);
      valid = false;
    }

    for (const input of formDataLevels) {
      if (!input.ecommerceCupon) {
        setValidForm(false);
        valid = false;
      }

      if (!input.cuponDescription) {
        setValidForm(false);
        valid = false;
      }

      if (!input.requiredPointsGetLevel || input.requiredPointsGetLevel < 0) {
        setValidForm(false);
        valid = false;
      }

      if (!input.pointsPerDollar || input.pointsPerDollar < 0) {
        setValidForm(false);
        valid = false;
      }

      if (!input.exclusivePageCode) {
        setValidForm(false);
        valid = false;
      }
    }

    if (!valid) {
      return;
    }

    setLoading(true);
    formData.levels = formDataLevels;
    const nftIdNotNull = id ? id : '';
    await editLoyaltyProgram(nftIdNotNull, formData);

    setFormData(formDataDefault);
    setFormDataLevels(formDataLevelsDefault);

    navigate('/', { replace: true });
    setLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    invalidNumberInputSymbols.includes(event.key) && event.preventDefault();
  };

  const handleOnPaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    try {
      const cleanString = event.clipboardData
        .getData('Text')
        .replace(/\D/g, '');
      const numericValue = parseInt(cleanString);

      const data = [...formDataLevels];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data[index] as any)[event.currentTarget.name] = numericValue;
      setFormDataLevels(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
    event.preventDefault();
  };

  return (
    <>
      <section className="author-area p-0">
        {loading ? (
          <div className="container text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">
                {intl.translate({ id: 'Loading...' })}
              </span>
            </div>
          </div>
        ) : (
          <div className="container">
            <BackButton />
            <div className="row justify-content-between">
              <div className="col-12 ml-md-auto">
                {/* Intro */}
                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                  <div className="intro-content">
                    <span>{intl.translate({ id: 'Loyalty Program' })}</span>
                    <h3 className="mt-3 mb-0">
                      {intl.translate({ id: 'Edit' })}
                    </h3>
                  </div>
                </div>
                {/* Item Form */}
                <form
                  className="item-form no-hover pt-0"
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <div className="row card mb-3">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <input
                          type="text"
                          className={`form-control ${
                            !validForm && !formData.name ? 'is-invalid' : ''
                          }`}
                          name="name"
                          placeholder={intl.translate({
                            id: 'Loyalty Program Name',
                          })}
                          required
                          value={formData.name}
                          disabled={loading}
                          onChange={handleNameInputChange}
                        />
                        <div
                          id="validationServer03Feedback"
                          className="invalid-feedback"
                        >
                          {intl.translate({
                            id: 'Please provide a Loyalty Program Name.',
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {formDataLevels.map((input, index) => {
                    return (
                      <div key={index} className="card my-3">
                        <div className="col-12 d-flex justify-content-between">
                          <h4 className="ml-3 my-3">Level {index + 1}</h4>
                          {formDataLevels.length > 1 ? (
                            <button
                              style={{ backgroundImage: 'none' }}
                              className="btn btn-danger mb-3 mb-sm-4"
                              disabled={loading}
                              onClick={(event) => deleteLevel(index, event)}
                            >
                              {intl.translate({ id: 'Remove level' })}
                            </button>
                          ) : null}
                        </div>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="form-group mt-3">
                              <input
                                type="text"
                                className={`form-control ${
                                  !validForm &&
                                  !formDataLevels[index].ecommerceCupon
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder={intl.translate({
                                  id: 'Ecommerce Coupon',
                                })}
                                required
                                name="ecommerceCupon"
                                value={input.ecommerceCupon}
                                onChange={(event) =>
                                  handleInputLevelChange(index, event)
                                }
                                disabled={loading}
                              />
                              <div
                                id="validationServer03Feedback"
                                className="invalid-feedback"
                              >
                                {intl.translate({
                                  id: 'Please provide an Ecommerce Coupon.',
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group mt-3">
                              <input
                                type="text"
                                className={`form-control ${
                                  !validForm &&
                                  !formDataLevels[index].exclusivePageCode
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder={intl.translate({
                                  id: 'Exclusive Page Code',
                                })}
                                required
                                name="exclusivePageCode"
                                value={input.exclusivePageCode}
                                onChange={(event) =>
                                  handleInputLevelChange(index, event)
                                }
                                disabled={loading}
                              />
                              <div
                                id="validationServer03Feedback"
                                className="invalid-feedback"
                              >
                                {intl.translate({
                                  id: 'Please provide an Exclusive Page Code.',
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group mt-3">
                              <StyledNumberInput
                                className={`form-control ${
                                  !validForm &&
                                  !formDataLevels[index].requiredPointsGetLevel
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder={intl.translate({
                                  id: 'Required Points',
                                })}
                                required
                                type="number"
                                name="requiredPointsGetLevel"
                                value={input.requiredPointsGetLevel}
                                onChange={(event) =>
                                  handleInputLevelChange(index, event)
                                }
                                onKeyDown={handleKeyDown}
                                onPaste={(event) => handleOnPaste(index, event)}
                                min={0}
                                max={9999999}
                                disabled={loading}
                              />
                              <div
                                id="validationServer03Feedback"
                                className="invalid-feedback"
                              >
                                {intl.translate({
                                  id: 'Please provide valid Required Points value.',
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6">
                            <div className="form-group mt-3">
                              <StyledNumberInput
                                className={`form-control ${
                                  !validForm &&
                                  !formDataLevels[index].pointsPerDollar
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder={intl.translate({
                                  id: 'Points Per Dollar',
                                })}
                                required
                                type="number"
                                name="pointsPerDollar"
                                value={input.pointsPerDollar}
                                onChange={(event) =>
                                  handleInputLevelChange(index, event)
                                }
                                onKeyDown={handleKeyDown}
                                onPaste={(event) => handleOnPaste(index, event)}
                                min={0}
                                max={9999999}
                                disabled={loading}
                              />
                              <div
                                id="validationServer03Feedback"
                                className="invalid-feedback"
                              >
                                {intl.translate({
                                  id: 'Please provide valid Points Per Dollar value.',
                                })}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="form-group mt-3">
                              <textarea
                                className={`form-control ${
                                  !validForm &&
                                  !formDataLevels[index].cuponDescription
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder={intl.translate({
                                  id: 'Coupon Description',
                                })}
                                cols={30}
                                rows={3}
                                name="cuponDescription"
                                value={input.cuponDescription}
                                onChange={(event) =>
                                  handleInputLevelChange(index, event)
                                }
                                disabled={loading}
                              />
                              <div
                                id="validationServer03Feedback"
                                className="invalid-feedback"
                              >
                                {intl.translate({
                                  id: 'Please provide a Description.',
                                })}
                              </div>
                            </div>
                          </div>
                          {formDataLevels.length === index + 1 && (
                            <div className="col-12">
                              <button
                                style={{ backgroundImage: 'none' }}
                                className="btn btn-primary mt-3 mt-sm-4 float-right"
                                disabled={loading}
                                onClick={addLevels}
                              >
                                {intl.translate({
                                  id: 'Add new level',
                                })}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="col-12">
                    <button
                      className="btn w-100 mt-3 mt-sm-4"
                      type="submit"
                      disabled={loading}
                    >
                      {!loading
                        ? intl.translate({
                            id: 'Save',
                          })
                        : intl.translate({
                            id: 'Saving...',
                          })}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default EditLoyaltyProgramView;
