/* eslint-disable @typescript-eslint/no-explicit-any */
import { Nft, NftMetadata } from 'types/nft-props';
import { http } from 'utilities/http/http';

export const getNftMetadataByTokenId = async (
  tokenId: number,
): Promise<NftMetadata> => {
  const response = await http.get<NftMetadata>(`nft/metadata/${tokenId}`);

  if (response) {
    return response;
  } else {
    throw new Error('getNftMetadataByTokenId error');
  }
};

export const getNftsByAddress = async (
  address: string,
): Promise<NftMetadata[]> => {
  const response = await http.get<any>(`nft/all/${address}`);

  if (response.success) {
    return response.data;
  } else {
    throw new Error('getNftsByAddress error');
  }
};

export const getFeaturedCollections = async (): Promise<Nft[]> => {
  const response = await http.get<any>(`nft-model/featured`);

  if (response.success) {
    return response.data;
  } else {
    throw new Error('getFeaturedCollections error');
  }
};
