import { createVendorApi, editVendorApi } from 'api/admin.api';
import BackButton from 'components/Common/BackButton/BackButton';
import PasswordForm from 'components/Password/PasswordForm';
import RoyaltiesForm from 'components/Royalties/RoyaltiesForm';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import useWallet from 'hooks/useWallet';
import * as WAValidator from 'multicoin-address-validator';
import { FC, useState } from 'react';
import { User } from 'types/users-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const nickNameMaxLength = 40;

const formDataDefault: Pick<
  User,
  'allowedHosts' | 'walletAddress' | 'nickName' | 'password' | 'repeatPassword'
> = {
  walletAddress: '',
  nickName: '',
  allowedHosts: [],
  password: '',
  repeatPassword: '',
};

interface Props {
  vendor?: Pick<
    User,
    | 'allowedHosts'
    | 'walletAddress'
    | 'nickName'
    | 'password'
    | 'repeatPassword'
  >;
  isEdit?: boolean;
  id?: string;
}

const VendorForm: FC<Props> = ({
  vendor = formDataDefault,
  isEdit = false,
  id = '',
}) => {
  const [formData, setFormData] = useState(vendor);
  const [validForm, setValidForm] = useState(true);
  const [validAddress, setValidAddress] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { updateVendorAddressForRoyalties, getUserBalance } = useWallet();

  //This function uses a regular expression to check if the password meets the following criteria:
  // (?=.*[A-Z]): Contains at least one uppercase letter
  // (?=.*[\W]): Contains at least one special character (non-word character)
  // (?=.{8,}): Is at least 8 characters long
  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*[\W])(?=.{8,})/;
    return regex.test(password);
  };

  const handleWalletInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      walletAddress: event.target.value,
    }));
  };

  const handleAllowedHostsInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      allowedHosts: event.target.value.split(',').map((host) => host.trim()),
    }));
  };

  const handleNickNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();
    setValidAddress(true);

    setFormData((values) => ({
      ...values,
      nickName: event.target.value,
    }));
  };

  const handlePasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      password: event.target.value,
    }));
  };

  const handleRepeatPasswordInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      repeatPassword: event.target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    let validation = true;

    const checkAddress = WAValidator.validate(formData.walletAddress, 'ETH');

    if (!checkAddress) {
      setValidAddress(false);
      setValidForm(false);
      validation = false;
    }

    if (!formData.walletAddress) {
      setValidForm(false);
      validation = false;
    }

    if (
      !isEdit &&
      (!formData.password ||
        formData.repeatPassword !== formData.password ||
        !validatePassword(formData.password))
    ) {
      setValidForm(false);
      validation = false;
    }

    if (!formData.nickName || formData.nickName.length > nickNameMaxLength) {
      setValidForm(false);
      validation = false;
    }

    if (!formData.allowedHosts) {
      setValidForm(false);
      validation = false;
    }

    if (!validation) {
      return;
    }

    try {
      setLoading(true);

      if (isEdit) {
        const balance = getUserBalance();
        if (
          vendor.walletAddress !== formData.walletAddress &&
          (isNaN(+balance) || balance === 0)
        ) {
          throw new Error('Insufficient balance for gas');
        }

        await editVendorApi(id, formData);
        if (vendor.walletAddress !== formData.walletAddress) {
          await updateVendorAddressForRoyalties(
            vendor.walletAddress,
            formData.walletAddress,
          );
        }
      } else {
        await createVendorApi(formData);
        setFormData({ ...formDataDefault });
      }
      setOpenSuccessModal(true);
      setValidForm(true);
      setValidAddress(true);
      // navigate(AppRoute.Admin, { replace: true });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="author-area p-0">
      <div className="container">
        <BackButton backTo={AppRoute.Admin} />
        <div className="row justify-content-between">
          <div className="col-12 ml-md-auto">
            {/* Intro */}
            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Vendor' })}</span>
                <h3 className="mt-3 mb-0">
                  {isEdit
                    ? intl.translate({ id: 'Edit Vendor' })
                    : intl.translate({ id: 'Create Vendor' })}
                </h3>
              </div>
            </div>
            {/* Item Form */}
            <form
              className="item-form no-hover pt-0"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="row card mb-3">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className={`form-control ${
                        !validForm && !formData.nickName ? 'is-invalid' : ''
                      }`}
                      name="nickName"
                      placeholder={intl.translate({ id: 'Nick Name' })}
                      required
                      value={formData.nickName}
                      disabled={loading}
                      onChange={handleNickNameInputChange}
                      maxLength={40}
                    />
                    <div
                      id="validationServer03Feedback"
                      className="invalid-feedback"
                    >
                      {intl.translate(
                        {
                          id: 'Please provide a Nick Name no longer than {maxLength}.',
                        },
                        { maxLength: nickNameMaxLength },
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row card mb-3">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className={`form-control ${
                        (!validForm && !formData.walletAddress) || !validAddress
                          ? 'is-invalid'
                          : ''
                      }`}
                      name="walletAddress"
                      placeholder={intl.translate({ id: 'Wallet Address' })}
                      required
                      value={formData.walletAddress}
                      disabled={loading}
                      onChange={handleWalletInputChange}
                    />
                    <div
                      id="validationServer03Feedback"
                      className="invalid-feedback"
                    >
                      {intl.translate({
                        id: 'Please provide a Wallet Address.',
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {!isEdit ? (
                <>
                  <div className="row card mb-3">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className={`form-control ${
                            !validForm &&
                            (!formData.password ||
                              !validatePassword(formData.password))
                              ? 'is-invalid'
                              : ''
                          }`}
                          name="password"
                          placeholder={intl.translate({ id: 'Password' })}
                          required
                          value={formData.password}
                          disabled={loading}
                          onChange={handlePasswordInputChange}
                        />
                        <div
                          id="validationServer03Feedback"
                          className="invalid-feedback"
                        >
                          {intl.translate({
                            id: 'Please provide a valid Password. It must contain one uppercase letter, one special character and 8 characters length.',
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row card mb-3">
                    <div className="col-12">
                      <div className="form-group mb-0">
                        <input
                          type="password"
                          className={`form-control ${
                            formData.repeatPassword &&
                            formData.repeatPassword !== formData.password
                              ? 'is-invalid'
                              : ''
                          }`}
                          name="repeatPassword"
                          placeholder={intl.translate({
                            id: 'Repeat Password',
                          })}
                          required
                          value={formData.repeatPassword}
                          disabled={loading}
                          onChange={handleRepeatPasswordInputChange}
                        />
                        <div
                          id="validationServer03Feedback"
                          className="invalid-feedback"
                        >
                          {intl.translate({
                            id: "Password doesn't match",
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row card mb-3">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className={`form-control ${
                        !validForm && !formData.allowedHosts ? 'is-invalid' : ''
                      }`}
                      name="allowedHost"
                      placeholder={intl.translate({
                        id: 'Allowed Hosts separated by coma. i.e. blockloyal.com, api.blockloyal:5000.com',
                      })}
                      required
                      value={formData.allowedHosts}
                      disabled={loading}
                      onChange={handleAllowedHostsInputChange}
                    />
                    <div
                      id="validationServer03Feedback"
                      className="invalid-feedback"
                    >
                      {intl.translate({
                        id: 'Please provide a valid host.',
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <button
                  className="btn w-100 mt-3 mt-sm-4"
                  type="submit"
                  disabled={loading}
                >
                  {!loading
                    ? isEdit
                      ? intl.translate({
                          id: 'Save',
                        })
                      : intl.translate({
                          id: 'Create',
                        })
                    : intl.translate({
                        id: 'Saving...',
                      })}
                </button>
              </div>
            </form>
          </div>
          {isEdit ? (
            <>
              <div className="col-12 mt-2">
                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                  <div className="intro-content">
                    <h3 className="mt-3 mb-0">
                      {intl.translate({ id: 'Password' })}
                    </h3>
                  </div>
                </div>
                <PasswordForm id={id} validatePassword={validatePassword} />
              </div>
              <div className="col-12 mt-2">
                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                  <div className="intro-content">
                    <h3 className="mt-3 mb-0">
                      {intl.translate({ id: 'Royalties' })}
                    </h3>
                  </div>
                </div>
                <RoyaltiesForm vendor={vendor} />
              </div>
            </>
          ) : null}
        </div>
      </div>
      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        onConfirm={() => null}
        description={errorMessage}
        okButtonText="OK"
        noCancelButton={true}
      />
      <ActionModal
        title={intl.translate({
          id: 'Success',
        })}
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        onConfirm={() => null}
        description={isEdit ? 'Saved successfully' : 'Created successfully'}
        okButtonText="OK"
        noCancelButton={true}
      />
    </section>
  );
};

export default VendorForm;
