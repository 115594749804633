/* eslint-disable @typescript-eslint/no-explicit-any */
import useWallet from 'hooks/useWallet';
import WAValidator from 'multicoin-address-validator';
import Papa from 'papaparse';
import { FC, useState } from 'react';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const defaultFormData: TransferDto = {
  filename: '',
  file: null,
};

type Props = {
  setOpenErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  setOpenSuccessModal: React.Dispatch<React.SetStateAction<boolean>>;
  nftId: string | undefined;
  vendorAddress: string | undefined;
};

const CsvTransferForm: FC<Props> = ({
  setOpenErrorModal,
  setErrorMessage,
  setOpenSuccessModal,
  nftId,
  vendorAddress,
}: Props) => {
  const { transferNftBatch } = useWallet();
  const [formData, setFormData] = useState(defaultFormData);
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);

  const placeholderFileLabel = intl.translate({ id: 'Choose file' });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      if (!isValidCSVFile(event.target.files[0].name)) {
        setValidForm(false);
      }
      setFormData({
        filename: event.target.files[0].name,
        file: event.target.files[0],
      });
    }
  };

  const isValidCSVFile = (filename: string | undefined) => {
    try {
      if (filename) {
        return filename.split('.').pop()?.toLowerCase() === 'csv';
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    }
    return false;
  };

  const getPropertiesAsArray = (object: any, property: string): string[] => {
    try {
      return Object.keys(object).map((res: any) => object[res][property]);
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    }
    return [];
  };

  const papaPromise = (file: File) =>
    new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {
          resolve(results);
        },
        error: function (error) {
          reject(error);
        },
      });
    });

  const getRequestValues = async () => {
    const walletsArray: string[] = [];
    const copiesArray: string[] = [];

    if (formData.file) {
      const results: any = await papaPromise(formData.file);
      walletsArray.push(...getPropertiesAsArray(results.data, 'Wallet'));
      copiesArray.push(
        ...getPropertiesAsArray(results.data, 'Copies to transfer'),
      );
    }

    return { walletsArray, copiesArray: copiesArray.map((copy) => +copy) };
  };

  const validateAllWallets = (walletsArray: string[]) => {
    const invalidWalletsArray = walletsArray.filter((wallet) => {
      return !WAValidator.validate(wallet, 'ETH');
    });

    if (invalidWalletsArray.length > 0) {
      throw new Error(
        `The following wallets on csv are invalid: "${invalidWalletsArray.join(
          ', ',
        )}."`,
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validation = true;

    if (!formData.file || !isValidCSVFile(formData.filename)) {
      setValidForm(false);
      validation = false;
    }

    if (!validation) {
      return;
    }

    setLoading(true);

    try {
      const { walletsArray, copiesArray } = await getRequestValues();
      validateAllWallets(walletsArray);
      if (vendorAddress && nftId) {
        await transferNftBatch(vendorAddress, nftId, walletsArray, copiesArray);
      } else {
        throw new Error('No vendor or NFT was found');
      }

      setOpenSuccessModal(true);
    } catch (err: any) {
      setOpenErrorModal(true);
      setErrorMessage(err.message);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      noValidate
      autoComplete="off"
      className="item-form card no-hover"
    >
      <div className="row">
        <div className="col-12">
          <div className="input-group form-group">
            <label className="custom-file-label" htmlFor="inputGroupFile01">
              {formData.filename ? formData.filename : placeholderFileLabel}
            </label>
            <input
              accept=".csv"
              id="contained-button-file"
              multiple={false}
              type="file"
              onChange={handleFileChange}
              className={`custom-file-input form-control ${
                !validForm &&
                (!formData.filename || !isValidCSVFile(formData.filename))
                  ? 'is-invalid'
                  : ''
              }`}
              placeholder={
                formData.filename ? formData.filename : placeholderFileLabel
              }
              required
            />
            <div id="validationServer03Feedback" className="invalid-feedback">
              {intl.translate({
                id: 'Please provide a valid csv file (.csv).',
              })}
            </div>
          </div>
        </div>
        <div className="col-12">
          <button
            className="btn w-100 mt-3 mt-sm-4"
            type="submit"
            disabled={loading}
          >
            {loading
              ? intl.translate({
                  id: 'Wait...',
                })
              : intl.translate({
                  id: 'Transfer',
                })}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CsvTransferForm;
