/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  createNftModelApi,
  getNftModelApi,
  getVendorApi,
  updateNftModelApi,
} from 'api/admin.api';
import BackButton from 'components/Common/BackButton/BackButton';
import { config } from 'config';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import { NftStatusEnum } from 'enums/nft.enum';
import { create, IPFSHTTPClient } from 'ipfs-http-client';
import { Align } from 'layout';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Nft, NftModelDto } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledNumberInput = styled.input`
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const StyledAlign = styled(Align)`
  height: 100%;
  width: 100%;
`;

// move to another file | helper
const auth =
  'Basic ' +
  Buffer.from(
    config.infura.projectId + ':' + config.infura.projectSecret,
  ).toString('base64');

const ipfsInfura: IPFSHTTPClient = create({
  url: config.infura.nodeUrl,
  headers: {
    authorization: auth,
  },
});

const invalidNumberInputSymbols = ['e', 'E', '+', '-', '.', ','];

type Vendor = {
  nickName?: string;
};

type File = {
  name: string;
};

const defaultVendor: Vendor = {};
const defaultFile: File = { name: '' };
const defaultFormData: NftModelDto = {
  image: '',
  title: '',
  tokenCopies: '',
  url: '',
  description: '',
  vendorId: '',
};

interface Props {
  isOnEditMode?: boolean;
}

const AdminCreateNftView: FC<Props> = ({ isOnEditMode }: Props) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(defaultFormData);
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const { vendorId, nftId } = useParams();
  const [vendor, setVendor] = useState(defaultVendor);
  const [selectedFile, setSelectedFile] = useState(defaultFile);
  const [nftIsMinted, setNftIsMinted] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const chooseFileLabel = intl.translate({ id: 'Choose file' });

  const uploadIPFS = async (fileToUpload: any) => {
    if (!ipfsInfura) {
      logger.info('Error loading IPFS');
      return;
    }

    const result = await ipfsInfura.add(fileToUpload);
    setFormData((values) => ({
      ...values,
      image: `https://ipfs.io/ipfs/${result.path}`,
    }));
  };

  const getExtension = (filename: string) => {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  };

  const isValidImage = useCallback((filename: string) => {
    const ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'png':
        return true;
    }
    return false;
  }, []);

  useEffect(() => {
    const getVendor = async () => {
      setLoading(true);

      const vendorIdNotNull = vendorId ? vendorId : '';
      const vendor = await getVendorApi(vendorIdNotNull);
      setVendor(vendor);

      setFormData((values) => ({
        ...values,
        vendorId: vendorIdNotNull,
      }));
      setLoading(false);
    };

    getVendor();
  }, [setVendor, vendorId]);

  useEffect(() => {
    if (isOnEditMode) {
      const getNft = async () => {
        setLoading(true);

        const nftIdNotNull = nftId ? nftId : '';
        const nft: Nft = await getNftModelApi(nftIdNotNull);

        setFormData((values) => ({
          ...values,
          ...nft,
        }));
        setNftIsMinted(nft.status === NftStatusEnum.Minted);
        setLoading(false);
      };

      getNft();
    }
  }, [isOnEditMode, nftId]);

  useEffect(() => {
    if (selectedFile && selectedFile !== defaultFile) {
      if (isValidImage(selectedFile?.name)) {
        uploadIPFS(selectedFile);
      }
    }
  }, [isValidImage, selectedFile]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      title: event.target.value,
    }));
  };

  const handleCopiesInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();
    if (event.target.value.length > 6) {
      return;
    }

    const safeParse = parseInt(event.target.value);
    const safeValue = isNaN(safeParse) ? '' : safeParse;

    setFormData((values) => ({
      ...values,
      tokenCopies: safeValue,
    }));
  };

  const handleUlrInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      url: event.target.value,
    }));
  };

  const handleDescriptionInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    event.persist();

    setFormData((values) => ({
      ...values,
      description: event.target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let validation = true;

    if (
      !isOnEditMode &&
      (!formData.image || !isValidImage(selectedFile?.name))
    ) {
      setValidForm(false);
      validation = false;
    }

    if (!formData.title) {
      setValidForm(false);
      validation = false;
    }

    if (!isOnEditMode && !formData.tokenCopies) {
      setValidForm(false);
      validation = false;
    }

    if (!formData.url) {
      setValidForm(false);
      validation = false;
    }

    if (!formData.description) {
      setValidForm(false);
      validation = false;
    }

    if (!validation) {
      return;
    }

    setLoading(true);

    try {
      const safeValue =
        typeof formData.tokenCopies === 'string'
          ? parseInt(formData.tokenCopies)
          : formData.tokenCopies;

      if (isOnEditMode && nftId) {
        await updateNftModelApi(nftId, {
          ...formData,
        });
      } else {
        await createNftModelApi({
          ...formData,
          tokenCopies: safeValue,
        });
      }

      navigate(`${AppRoute.AdminListVendorNFT}/${vendorId}`, { replace: true });
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    invalidNumberInputSymbols.includes(event.key) && event.preventDefault();
  };

  const handleOnPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    try {
      const cleanString = event.clipboardData
        .getData('Text')
        .replace(/\D/g, '');
      if (cleanString.length > 6) {
        return;
      }
      const numericValue = parseInt(cleanString);

      setFormData((values) => ({
        ...values,
        tokenCopies: numericValue,
      }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
    event.preventDefault();
  };

  return (
    <StyledAlign v-center h-center>
      <section className="author-area p-0">
        <div className="container">
          <BackButton />
          <div className="row justify-content-between">
            <div className="col-12">
              {/* Intro */}
              <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
                <div className="intro-content">
                  <span>{intl.translate({ id: 'Admin' })}</span>
                  <h3 className="mt-3 mb-0">
                    {isOnEditMode
                      ? intl.translate({ id: 'Edit NFT for:' })
                      : intl.translate({ id: 'Create NFT for:' })}{' '}
                    {vendor?.nickName}
                  </h3>
                </div>
              </div>
              {/* Item Form */}
              <form
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
                className="item-form card no-hover"
              >
                <div className="row">
                  <div className="col-12">
                    <div className="input-group form-group">
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile01"
                      >
                        {selectedFile?.name
                          ? selectedFile.name
                          : chooseFileLabel}
                      </label>
                      <input
                        accept=".jpg, .jpeg, .png, .gif"
                        id="contained-button-file"
                        multiple={false}
                        type="file"
                        onChange={(event) => {
                          if (event.target.files !== null) {
                            setSelectedFile(event.target.files[0]);
                          }
                        }}
                        className={`custom-file-input form-control ${
                          !validForm && !formData.image ? 'is-invalid' : ''
                        }`}
                        placeholder={
                          selectedFile?.name
                            ? selectedFile.name
                            : chooseFileLabel
                        }
                        required
                        disabled={loading || isOnEditMode}
                      />
                      <div
                        id="validationServer03Feedback"
                        className="invalid-feedback"
                      >
                        {intl.translate({
                          id: 'Please provide a valid NFT image (.jpg, .jpeg, .png, .gif).',
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group mt-3">
                      <input
                        type="text"
                        name="title"
                        placeholder={intl.translate({
                          id: 'Title',
                        })}
                        required
                        className={`form-control ${
                          !validForm && !formData.title ? 'is-invalid' : ''
                        }`}
                        value={formData.title}
                        onChange={handleTitleChange}
                        disabled={loading}
                      />
                      <div
                        id="validationServer03Feedback"
                        className="invalid-feedback"
                      >
                        {intl.translate({
                          id: 'Please provide a Title.',
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <StyledNumberInput
                        type="number"
                        name="tokenCopies"
                        placeholder={intl.translate({
                          id: 'Copies',
                        })}
                        required
                        disabled={isOnEditMode && nftIsMinted}
                        className={`form-control ${
                          !validForm && !formData.tokenCopies
                            ? 'is-invalid'
                            : ''
                        }`}
                        onChange={handleCopiesInputChange}
                        onKeyDown={handleKeyDown}
                        onPaste={handleOnPaste}
                        min={0}
                        max={9999999}
                        value={formData.tokenCopies}
                      />
                      <div
                        id="validationServer03Feedback"
                        className="invalid-feedback"
                      >
                        {intl.translate({
                          id: 'Please provide amount of Copies.',
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="url"
                        placeholder={intl.translate({
                          id: 'URL',
                        })}
                        value={formData.url}
                        onChange={handleUlrInputChange}
                        disabled={loading}
                        className={`form-control ${
                          !validForm && !formData.url ? 'is-invalid' : ''
                        }`}
                      />
                      <div
                        id="validationServer03Feedback"
                        className="invalid-feedback"
                      >
                        {intl.translate({
                          id: 'Please provide an URL.',
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        className={`form-control ${
                          !validForm && !formData.description
                            ? 'is-invalid'
                            : ''
                        }`}
                        placeholder={intl.translate({
                          id: 'Description',
                        })}
                        cols={30}
                        rows={3}
                        name="desription"
                        value={formData.description}
                        onChange={handleDescriptionInputChange}
                        disabled={loading}
                      />
                      <div
                        id="validationServer03Feedback"
                        className="invalid-feedback"
                      >
                        {intl.translate({
                          id: 'Please provide a Description.',
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn w-100 mt-3 mt-sm-4"
                      type="submit"
                      disabled={loading}
                    >
                      {loading
                        ? intl.translate({
                            id: 'Wait...',
                          })
                        : isOnEditMode
                        ? intl.translate({
                            id: 'Save changes',
                          })
                        : intl.translate({
                            id: 'Create',
                          })}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ActionModal
          title={intl.translate({
            id: 'Error',
          })}
          open={openErrorModal}
          setOpen={setOpenErrorModal}
          onConfirm={() => null}
          description={errorMessage}
          okButtonText="OK"
          noCancelButton={true}
        />
      </section>
    </StyledAlign>
  );
};

export default AdminCreateNftView;
