import { isAdmin, isVendor } from 'components/Auth/Auth.provider';
import { AppRoute } from 'enums/app-route.enum';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';

type propTypes = { children: ReactNode };
const PrivateRouteVendor: FC<propTypes> = ({ children }) => {
  if (isVendor()) {
    return <>{children}</>;
  }

  if (isAdmin()) {
    return <Navigate to={AppRoute.Admin} />;
  }

  return <Navigate to={AppRoute.Customer} />;
};

export { PrivateRouteVendor };
