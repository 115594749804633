import { darkTheme, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import AppBar from 'components/AppBar/AppBar';
import { AuthProvider } from 'components/Auth/Auth.provider';
import { chains, wagmiClient } from 'components/Auth/rainbowkit';
import Footer from 'components/Footer/Footer';
import { Align } from 'layout';
import React, { useContext } from 'react';
import { Router } from 'Router';
import styled from 'styled-components';
import { IntlContext } from 'utilities/i18n/intl.context';
import { WagmiConfig } from 'wagmi';

const StyledDiv = styled.div`
  padding: 2rem 0rem 4rem 0rem;
  background-color: ${({ theme }) => theme.background.back};
  min-height: 70vh;

  a {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const StyledAlign = styled(Align)`
  /* height: calc(100vh - 9rem); */
  width: 100%;
  margin-top: 6rem;
`;

function App() {
  // necessary to force a render when locale is updated
  useContext(IntlContext);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={chains}
        theme={darkTheme({
          overlayBlur: 'small',
          ...darkTheme.accentColors.purple,
        })}
        modalSize="compact"
        showRecentTransactions={true}
      >
        <React.StrictMode>
          <AuthProvider>
            <AppBar />
            <StyledDiv>
              <StyledAlign v-center h-center>
                <main>
                  <Router />
                </main>
              </StyledAlign>
            </StyledDiv>
            <Footer />
          </AuthProvider>
        </React.StrictMode>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
