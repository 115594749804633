export enum AppRoute {
  Home = '/',
  MyNFTs = '/',
  Login = '/login',
  ConnectWallet = '/connect-wallet',
  Admin = '/admin',
  AdminCreateVendor = '/admin/create-vendor',
  AdminEditVendor = '/admin/edit-vendor',
  AdminCreateNFT = '/admin/create-nft',
  AdminListVendorNFT = '/admin/list-nft',
  CreateLoyaltyProgram = '/create-loyalty-program',
  EditLoyaltyProgram = '/edit-loyalty-program',
  Customer = '/customer',
  Vendor = '/vendor',
  TransferMultiple = '/transfer-multiple',
  NFT = '/nft',
  Test = '/test-library',
  PrivacyPolicy = '/privacy-policy',
  Docs = '/docs',
}
