import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { isAdmin, isVendor } from 'components/Auth/Auth.provider';
import { AppRoute } from 'enums/app-route.enum';

type propTypes = { children: ReactNode };
const RedirectAuthType: FC<propTypes> = ({ children }) => {
  if (isAdmin()) {
    return <Navigate to={AppRoute.Admin} />;
  }

  if (isVendor()) {
    return <Navigate to={AppRoute.Home} />;
  }

  return <>{children}</>;
};

export { RedirectAuthType };
