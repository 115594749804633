import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import Button from '@mui/material/Button';
import React, { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

interface ModalProps {
  title: string;
  description: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  okButtonText: 'OK' | 'Confirm';
  noCancelButton?: boolean;
}

export const ActionModal: FC<ModalProps> = ({
  title,
  description,
  open,
  setOpen,
  onConfirm,
  okButtonText,
  noCancelButton,
}) => {
  return (
    <Dialog
      fullWidth={true}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      aria-describedby="confirm-dialog-description"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        {description ? (
          <DialogContentText id="confirm-dialog-description">
            {description}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        {noCancelButton ? null : (
          <Button variant="outlined" onClick={() => setOpen(false)}>
            {intl.translate({ id: 'Cancel' })}
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {okButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionModal;
