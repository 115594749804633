import { isAdmin, isVendor } from 'components/Auth/Auth.provider';
import { AppRoute } from 'enums/app-route.enum';
import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';

type propTypes = { children: ReactNode };
const PrivateRouteAdmin: FC<propTypes> = ({ children }) => {
  if (isAdmin()) {
    return <>{children}</>;
  }

  if (isVendor()) {
    return <Navigate to={AppRoute.Home} />;
  }

  return <Navigate to={AppRoute.ConnectWallet} />;
};

export { PrivateRouteAdmin };
