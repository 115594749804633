import { FC, useEffect, useState } from 'react';
import { getVendorApi } from 'api/admin.api';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Align } from 'layout';
import { User } from 'types/users-props';
import VendorForm from 'components/VendorForm/VendorForm';
import Loading from 'components/Common/Loading/Loading';

const StyledAlign = styled(Align)`
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 700px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 1020px;
  }
`;

const AdminEditVendorView: FC = () => {
  const [loading, setLoading] = useState(false);
  const [vendor, setVendor] = useState<User>();
  const { id } = useParams();

  useEffect(() => {
    const loadVendor = async (id: string) => {
      setLoading(true);
      const _vendor = await getVendorApi(id);
      setVendor(_vendor);
      setLoading(false);
    };

    if (id) {
      loadVendor(id);
    }
  }, [id]);

  return (
    <StyledAlign v-center h-center>
      {!loading ? (
        <VendorForm vendor={vendor} isEdit={true} id={id}></VendorForm>
      ) : (
        <Loading />
      )}
    </StyledAlign>
  );
};

export default AdminEditVendorView;
