/* eslint-disable @typescript-eslint/no-explicit-any */
import { StorageItem } from 'enums/storage-item.enum';
import { storage } from 'helpers/storage.helpers';
import { FC, ReactNode } from 'react';
import { createProvider, Provider } from 'react-branch-provider';
import { AuthProps } from 'types/auth-props';
import { http } from 'utilities/http/http';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const initialState: AuthProps = {
  tokenJwt: storage.local.get(StorageItem.AUTH_TOKEN),
  user: JSON.parse(storage.local.get(StorageItem.USER, '{}')),
  address: storage.local.get(StorageItem.ADDRESS),
};

export const authProvider = createProvider(initialState);

export const getNonce = async (data: any) => {
  const responseSignUp = await http.post<any>('/user/signup', {
    address: data?.address,
  });

  if (responseSignUp.success) {
    const user = responseSignUp.data.user;

    const userString = JSON.stringify(responseSignUp.data);
    authProvider.setState((state) => {
      storage.local.set(StorageItem.USER, userString);
      storage.local.set(StorageItem.ADDRESS, data?.address);
      state.user = user;
    });

    return responseSignUp.data.nonceMessage;
  } else {
    throw new Error(intl.translate({ id: 'signup error' }));
  }
};

export const userLogIn = async (data: any) => {
  const responseLogin = await http.post<any>('/auth/login', {
    address: data.address,
    signature: data.signature,
  });

  if (responseLogin.success) {
    const tokenJwt = responseLogin.data.token;
    const user = JSON.stringify(responseLogin.data.user);

    authProvider.setState((state) => {
      storage.local.set(StorageItem.AUTH_TOKEN, tokenJwt);
      storage.local.set(StorageItem.USER, user);
      storage.local.set(StorageItem.ADDRESS, data.address);
      state.tokenJwt = tokenJwt;
      state.user = JSON.parse(user);
      state.address = data.address;
    });
    logger.info('Login successful');
  } else {
    throw new Error('login error');
  }
};

export const userLogOut = () => {
  authProvider.setState((state) => {
    storage.local.remove(StorageItem.AUTH_TOKEN);
    storage.local.remove(StorageItem.USER);
    storage.local.remove(StorageItem.ADDRESS);
    state.tokenJwt = null;
    state.user = null;
    state.address = null;
  });
};

export const isAuthenticated = () => {
  return storage.local.get(StorageItem.AUTH_TOKEN);
};

export const isAdmin = () => {
  const user = JSON.parse(storage.local.get(StorageItem.USER, '{}'));
  return isAuthenticated() && user?.userType === 'admin';
};

export const isVendor = () => {
  const user = JSON.parse(storage.local.get(StorageItem.USER, '{}'));
  return isAuthenticated() && user?.userType === 'vendor';
};

type propTypes = { children: ReactNode };

export const AuthProvider: FC<propTypes> = ({ children }) => {
  return <Provider state={authProvider}>{children}</Provider>;
};
