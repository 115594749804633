/* eslint-disable @typescript-eslint/no-explicit-any */
import { Nft, NftDto, NftModelDto } from 'types/nft-props';
import { User, UserDto, UsersDto } from 'types/users-props';
import { http } from 'utilities/http/http';

export const listVendorApi = async () => {
  try {
    const response = await http.get<UsersDto>('/user');

    if (response.success) {
      // filter active nfts
      response.data?.forEach((user: User) => {
        user.listOwnedNFts = user.listOwnedNFts?.filter(
          (nft: Nft) => nft.active,
        );
      });

      return response.data;
    } else {
      throw new Error('listVendorApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const listNftModelAdminApi = async (vendorId: string) => {
  try {
    const response = await http.get<any>(`/nft-model/${vendorId}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('listNftModelAdminApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getVendorApi = async (id: string) => {
  try {
    const response = await http.get<UserDto>(`/user/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('getVendorApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const createVendorApi = async (
  data: Pick<User, 'allowedHosts' | 'walletAddress' | 'nickName'>,
) => {
  try {
    const response = await http.post<UsersDto>('user', data);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('createVendor error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const editVendorApi = async (
  id: string,
  data: Pick<User, 'allowedHosts' | 'walletAddress' | 'nickName'>,
) => {
  try {
    const response = await http.put<UsersDto>(`/user/${id}`, data);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('editVendorApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const editVendorPasswordApi = async (
  id: string,
  data: Pick<User, 'password'>,
) => {
  try {
    const response = await http.put<UsersDto>(`/user/password/${id}`, data);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('editVendorPasswordApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const deleteVendorApi = async (id: string) => {
  try {
    const response = await http.put<any>(`/user/inactive/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('deleteVendorApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getNftModelApi = async (id: string) => {
  try {
    const response = await http.get<any>(`nft-model/id/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('getNftModelApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const createNftModelApi = async (nftModelDtoData: NftModelDto) => {
  try {
    const response = await http.post<any>('nft-model', nftModelDtoData);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('createNftModelApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const updateNftModelApi = async (
  id: string,
  nftModelDtoData: NftModelDto,
) => {
  try {
    const response = await http.put<any>(`nft-model/${id}`, nftModelDtoData);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('updateNftModelApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const updateNftModelToMintedApi = async (
  id: string,
  idValues: { tokenRangeFrom: number; tokenRangeTo: number },
) => {
  try {
    const response = await http.put<any>(`nft-model/minted/${id}`, idValues);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('updateNftModelToMintedApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const deleteNftModelApi = async (id: string) => {
  try {
    const response = await http.delete<any>(`nft-model/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('deleteNftModelApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const createNftApi = async (nftDtoData: NftDto) => {
  try {
    const response = await http.post<any>('nft', nftDtoData);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('createNftApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};
