/* eslint-disable @typescript-eslint/no-explicit-any */
import { LoyaltyProgram, NftModel } from 'types/nft-props';
import { http } from 'utilities/http/http';

export async function listNftOfVendorApi(): Promise<NftModel[]> {
  const response = await http.get<any>('/nft-model/vendor');

  if (response.success) {
    return response.data;
  } else {
    throw new Error('listNftOfVendorApi error');
  }
}

export const getLoyaltyProgram = async (id: string) => {
  const response = await http.get<any>(`/loyaltyProgram/${id}`);

  if (response.success) {
    return response.data;
  } else {
    throw new Error('getLoyaltyProgram error');
  }
};

export const createLoyaltyProgram = async (
  nftId: string,
  data: LoyaltyProgram,
) => {
  const response = await http.post<any>(`/loyaltyProgram/nft/${nftId}`, data);

  if (response.success) {
    return response.data;
  } else {
    throw new Error('createLoyaltyProgram error');
  }
};

export const editLoyaltyProgram = async (id: string, data: LoyaltyProgram) => {
  const response = await http.put<any>(`/loyaltyProgram/${id}`, data);

  if (response.success) {
    return response.data;
  } else {
    throw new Error('editLoyaltyProgram error');
  }
};

export const deleteLoyaltyProgram = async (id: string) => {
  const response = await http.delete<any>(`/loyaltyProgram/${id}`, {});

  if (response.success) {
    return response.data;
  } else {
    throw new Error(`deleteLoyaltyProgram error: ${response.data.message}`);
  }
};

export const getNftVendorApi = async (id: string) => {
  try {
    const response = await http.get<any>(`nft-model/vendor/id/${id}`);

    if (response.success) {
      return response.data;
    } else {
      throw new Error('createNftApi error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};

export const getTokenIdsForVendorNFT = async (
  vendorAddress: string,
  nftModelId: string,
): Promise<{ amount: number; ids: number[] }> => {
  try {
    const response = await http.get<any>(
      `nft/vendor/${vendorAddress}/${nftModelId}`,
    );

    if (response.success) {
      return response.data;
    } else {
      throw new Error('getTokenIdsForVendorNFT error');
    }
  } catch (err: any) {
    throw new Error(err.message);
  }
};
