/* eslint-disable react/prop-types */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { deleteVendorApi, listVendorApi } from 'api/admin.api';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import { UserType } from 'enums/user-type.enum';
import Moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { User } from 'types/users-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledSection = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: 47rem;
  }
`;

const StyledEllipsisWalletSpan = styled.span`
  display: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 400px;
`;

const vendorDefault: User = {
  id: '',
  walletAddress: '',
  nickName: '',
  userType: UserType.Vendor,
  active: false,
  createdAt: '',
  allowedHosts: [],
  listOwnedNFts: [],
  listCreatedNfts: [],
};
const vendorsListDefault: User[] = [{ ...vendorDefault }];

const AdminListVendorView: FC = () => {
  const [loading, setLoading] = useState(false);
  const [vendors, setVendors] = useState(vendorsListDefault);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>('');

  const getVendors = async () => {
    setLoading(true);
    const returnvendors = await listVendorApi();
    setVendors(returnvendors);
    setLoading(false);
  };

  const onClickDelete = (id: string) => {
    setConfirmOpen(true);
    setSelectedId(id);
  };

  const onConfirmDelete = async () => {
    try {
      await deleteVendorApi(selectedId);
      setVendors((previousState) =>
        previousState.filter((vendor: User) => vendor.id !== selectedId),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
  };

  useEffect(() => {
    getVendors();
  }, []);

  return (
    <StyledSection className="explore-area load-more p-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="intro d-flex justify-content-between align-items-end m-0">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Admin' })}</span>
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'Vendor List' })}
                </h3>
                <p>{intl.translate({ id: 'List of all available Vendors' })}</p>
              </div>
              <div className="intro-btn">
                <Link
                  to={AppRoute.AdminCreateVendor}
                  className="btn content-btn"
                >
                  {intl.translate({ id: 'Create Vendor' })}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="row items mt-4">
          {loading ? (
            <div className="container text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">
                  {intl.translate({ id: 'Loading...' })}
                </span>
              </div>
            </div>
          ) : (
            <TableContainer
              component={Paper}
              style={{ backgroundColor: '#16151A' }}
            >
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell className="font-weight-bold">Name</TableCell>
                    <TableCell className="font-weight-bold" align="center">
                      {intl.translate({ id: 'Owned NFTs' })}
                    </TableCell>
                    <TableCell
                      className="font-weight-bold"
                      align="center"
                      colSpan={3}
                    >
                      {intl.translate({ id: 'Actions' })}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vendors?.map((vendor, index) => (
                    <VendorInfo
                      key={index}
                      vendor={vendor}
                      onClickDelete={onClickDelete}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
      <ActionModal
        title={intl.translate({
          id: 'Delete Vendor',
        })}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={onConfirmDelete}
        description={intl.translate({
          id: 'Are you sure? Please confirm',
        })}
        okButtonText="Confirm"
      />
    </StyledSection>
  );
};

interface Props {
  vendor: User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickDelete: any;
}

const VendorInfo: FC<Props> = ({ vendor, onClickDelete }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <StyledEllipsisWalletSpan>{vendor.nickName}</StyledEllipsisWalletSpan>
        </TableCell>
        <TableCell align="center">{vendor.listOwnedNFts?.length}</TableCell>
        <TableCell align="right">
          {vendor.listOwnedNFts?.length > 0 ? (
            <Link
              to={`${AppRoute.AdminListVendorNFT}/${vendor.id}`}
              style={{ backgroundImage: 'none' }}
              className="btn btn-info text-white"
            >
              {intl.translate({ id: 'View NFTs' })}
            </Link>
          ) : (
            <Link
              to={`${AppRoute.AdminCreateNFT}/${vendor.id}`}
              style={{ backgroundImage: 'none' }}
              className="btn btn-success text-white"
            >
              {intl.translate({ id: 'Create NFT' })}
            </Link>
          )}
        </TableCell>
        <TableCell align="right">
          <Link
            to={`${AppRoute.AdminEditVendor}/${vendor.id}`}
            style={{ backgroundImage: 'none' }}
            className="btn btn-primary text-white"
          >
            {intl.translate({ id: 'Edit' })}
          </Link>
        </TableCell>
        <TableCell align="right">
          <button
            style={{ backgroundImage: 'none' }}
            className="btn btn-danger text-white"
            onClick={() => onClickDelete(vendor.id)}
          >
            {intl.translate({ id: 'Delete' })}
          </button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className="p-4">
              <Typography variant="h6" gutterBottom component="div">
                {intl.translate({ id: 'Details' })}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className="font-weight-bold">Wallet</TableCell>
                    <TableCell className="font-weight-bold">
                      {intl.translate({ id: 'Creation date' })}
                    </TableCell>
                    <TableCell className="font-weight-bold">Active</TableCell>
                    {/* <TableCell className="font-weight-bold" align="right">
                      3
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      {vendor.walletAddress}
                    </TableCell>
                    <TableCell>
                      {Moment().format(vendor.createdAt.split('T')[0])}
                    </TableCell>
                    <TableCell>
                      {vendor.active
                        ? intl.translate({ id: 'Yes' })
                        : intl.translate({ id: 'No' })}
                    </TableCell>
                    {/* <TableCell align="right">3</TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AdminListVendorView;
