import { PrivateRouteAdmin } from 'components/ProtectedRoute/PrivateRouteAdmin';
import { PrivateRouteCustomer } from 'components/ProtectedRoute/PrivateRouteCustomer';
import { PrivateRouteInternal } from 'components/ProtectedRoute/PrivateRouteInternal';
import { PrivateRouteVendor } from 'components/ProtectedRoute/PrivateRouteVendor';
import { RedirectAuthType } from 'components/ProtectedRoute/RedirectAuthType';
import { Text } from 'design';
import { AppRoute } from 'enums/app-route.enum';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';
import AdminCreateNftView from 'views/Admin/AdminCreateNftView';
import AdminCreateVendorView from 'views/Admin/AdminCreateVendorView';
import AdminNFTDetailedView from 'views/Admin/AdminDetailedNftView';
import AdminEditVendorView from 'views/Admin/AdminEditVendorView';
import AdminListNftView from 'views/Admin/AdminListNftView';
import AdminListVendorView from 'views/Admin/AdminListVendorView';
import CustomerNFTDetailedView from 'views/Customer/CustomerDetailedNFTView';
import CustomerListNftView from 'views/Customer/CustomerListNftView';
import Docs from 'views/Docs/DocsView';
import LoginView from 'views/Login/LoginView';
import PrivacyPolicy from 'views/PrivacyPolicy/PrivacyPolicyView';
import TestLibrary from 'views/TestLibrary/TestLibrary';
import CreateLoyaltyProgramView from 'views/Vendor/CreateLoyaltyProgramView';
import EditLoyaltyProgramView from 'views/Vendor/EditLoyaltyProgramView';
import TransferMultipleView from 'views/Vendor/TransferMultipleView/TransferMultipleView';
import VendorNFTDetailedView from 'views/Vendor/VendorDetailedNftView';
import VendorListNftView from 'views/Vendor/VendorListNftView';

export const Router: FC = () => {
  return (
    <Routes>
      <Route
        path={AppRoute.ConnectWallet}
        element={
          <RedirectAuthType>
            <LoginView />
          </RedirectAuthType>
        }
      />

      <Route
        path={AppRoute.MyNFTs}
        element={
          <PrivateRouteVendor>
            <VendorListNftView />
          </PrivateRouteVendor>
        }
      />

      <Route
        path={`${AppRoute.CreateLoyaltyProgram}/:nftId`}
        element={
          <PrivateRouteVendor>
            <CreateLoyaltyProgramView />
          </PrivateRouteVendor>
        }
      />

      <Route
        path={`${AppRoute.Customer}${AppRoute.NFT}/:id`}
        element={
          <PrivateRouteCustomer>
            <CustomerNFTDetailedView />
          </PrivateRouteCustomer>
        }
      />

      <Route
        path={`${AppRoute.Vendor}${AppRoute.NFT}/:id`}
        element={
          <PrivateRouteVendor>
            <VendorNFTDetailedView />
          </PrivateRouteVendor>
        }
      />

      <Route
        path={`${AppRoute.Vendor}${AppRoute.TransferMultiple}/:id`}
        element={
          <PrivateRouteVendor>
            <TransferMultipleView />
          </PrivateRouteVendor>
        }
      />

      <Route
        path={`${AppRoute.Admin}${AppRoute.NFT}/:id`}
        element={
          <PrivateRouteAdmin>
            <AdminNFTDetailedView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={`${AppRoute.EditLoyaltyProgram}/:id`}
        element={
          <PrivateRouteVendor>
            <EditLoyaltyProgramView />
          </PrivateRouteVendor>
        }
      />

      <Route
        path={AppRoute.Admin}
        element={
          <PrivateRouteAdmin>
            <AdminListVendorView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={AppRoute.Customer}
        element={
          <PrivateRouteCustomer>
            <CustomerListNftView />
          </PrivateRouteCustomer>
        }
      />

      <Route
        path={`${AppRoute.AdminCreateNFT}/:vendorId`}
        element={
          <PrivateRouteAdmin>
            <AdminCreateNftView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={`${AppRoute.AdminCreateNFT}/:vendorId/edit/:nftId`}
        element={
          <PrivateRouteAdmin>
            <AdminCreateNftView isOnEditMode={true} />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={AppRoute.AdminCreateVendor}
        element={
          <PrivateRouteAdmin>
            <AdminCreateVendorView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={`${AppRoute.AdminEditVendor}/:id`}
        element={
          <PrivateRouteAdmin>
            <AdminEditVendorView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={`${AppRoute.AdminListVendorNFT}/:vendorId`}
        element={
          <PrivateRouteAdmin>
            <AdminListNftView />
          </PrivateRouteAdmin>
        }
      />

      <Route
        path={AppRoute.Test}
        element={
          <PrivateRouteInternal>
            <TestLibrary />
          </PrivateRouteInternal>
        }
      />

      <Route path={AppRoute.PrivacyPolicy} element={<PrivacyPolicy />} />
      <Route path={AppRoute.Docs} element={<Docs />} />

      <Route
        path="*"
        element={
          <>
            <Text.h2>{intl.translate({ id: 'Error 404' })}</Text.h2>

            <Text.p>
              {intl.translate(
                {
                  id: "We couldn't find what you were looking for. If you whish you can <a>Go Home</a>",
                },
                {
                  a: (label) => <a href={AppRoute.Home}>{label}</a>,
                },
              )}
            </Text.p>
          </>
        }
      />
    </Routes>
  );
};
