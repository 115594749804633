import { FC } from 'react';
import { intl } from 'utilities/i18n/intl.utility';

const Loading: FC = () => {
  return (
    <div className="container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="sr-only">{intl.translate({ id: 'Loading...' })}</span>
      </div>
    </div>
  );
};

export default Loading;
