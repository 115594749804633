import { ConnectButton } from '@rainbow-me/rainbowkit';
import logo from 'assets/logo.png';
import {
  authProvider,
  isAdmin,
  isAuthenticated,
  isVendor,
} from 'components/Auth/Auth.provider';
import ModalMenu from 'design/Modal/ModalMenu';
import { AppRoute } from 'enums/app-route.enum';
import useAuth from 'hooks/useAuth';
import { FC, useEffect } from 'react';
import { useBranchState } from 'react-branch-provider';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { useAccount, useDisconnect } from 'wagmi';

const StyledNav = styled.nav`
  background-color: ${({ theme }) => theme.background.middle};
`;

const AppBar: FC = () => {
  const state = useBranchState(authProvider);
  const navigate = useNavigate();
  const { logout, login } = useAuth();
  const { disconnect } = useDisconnect();

  const { address, isDisconnected } = useAccount({
    onConnect() {
      if (!isAuthenticated()) {
        login();
      }
    },
  });

  useEffect(() => {
    if (
      !isAuthenticated() &&
      window.location.pathname !== AppRoute.PrivacyPolicy &&
      window.location.pathname !== AppRoute.Docs
    ) {
      navigate(AppRoute.ConnectWallet, { replace: true });
    }
  }, [state, navigate]);

  useEffect(() => {
    if (isDisconnected || (state.address && address !== state.address)) {
      logout();
      if (!isDisconnected) {
        disconnect();
      }
    }
  }, [isDisconnected, address, logout, disconnect, state.address]);

  return (
    <>
      <header id="header">
        {/* Navbar */}
        <StyledNav
          data-aos="zoom-out"
          data-aos-delay={800}
          className="navbar navbar-expand"
        >
          <div className="container header">
            {/* Navbar Brand*/}
            <Link to={AppRoute.Home} className="navbar-brand">
              <img
                className="navbar-brand-sticky"
                src={logo}
                alt="sticky brand-logo"
              />
            </Link>
            <div className="ml-auto" />

            {/* Navbar */}
            {isAuthenticated() ? (
              <ul className="navbar-nav items mx-auto">
                {isAdmin() ? (
                  <li className="nav-item dropdown">
                    <Link to={AppRoute.Admin} className="nav-link">
                      {intl.translate({ id: 'Vendors' })}
                    </Link>
                  </li>
                ) : null}
                {isVendor() ? (
                  <li className="nav-item dropdown">
                    <Link to={AppRoute.MyNFTs} className="nav-link">
                      {intl.translate({ id: 'My NFTs' })}
                    </Link>
                  </li>
                ) : null}
              </ul>
            ) : null}
            <ConnectButton />
          </div>
        </StyledNav>
      </header>

      <ModalMenu />
    </>
  );
};

export default AppBar;
