import { getFeaturedCollections } from 'api/customer.api';
import NftCollectionCard from 'components/NftCard/NftCollectionCard/NftCollectionCard';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Nft } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledItemsDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 1170px;
  }
`;

const Collections = () => {
  const [collectionData, setCollectionData] = useState<Nft[]>([]);

  const getCollections = async () => {
    try {
      const collections = await getFeaturedCollections();
      setCollectionData(collections);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logger.error(error);
    }
  };

  useEffect(() => {
    getCollections();
  }, []);

  if (!collectionData.length) {
    return null;
  }

  return (
    <section className="py-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* Intro */}
            <div className="intro d-flex justify-content-between align-items-end m-0">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Featured' })}</span>
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'Popular Collections' })}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <StyledItemsDiv className="row items">
          {collectionData.map((item, idx) => {
            return <NftCollectionCard key={idx} nft={item} />;
          })}
        </StyledItemsDiv>
      </div>
    </section>
  );
};

export default Collections;
