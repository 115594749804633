import { FC, ReactNode } from 'react';
import { Navigate } from 'react-router';
import { AppRoute } from 'enums/app-route.enum';
import { config } from 'config';
import { useBranchState } from 'react-branch-provider';
import { authProvider } from 'components/Auth/Auth.provider';

type propTypes = { children: ReactNode };
const PrivateRouteInternal: FC<propTypes> = ({ children }) => {
  const state = useBranchState(authProvider);
  const internalTestingWallet = config.base.internalTestingWallet;

  if (internalTestingWallet && internalTestingWallet === state.address) {
    return <>{children}</>;
  }

  return <Navigate to={AppRoute.ConnectWallet} />;
};

export { PrivateRouteInternal };
