import { intl } from 'utilities/i18n/intl.utility';

interface Props {
  completed: boolean;
  handleMore: () => void;
}

const LoadMore = ({ completed, handleMore }: Props) => {
  if (completed) {
    return null;
  }

  return (
    <div className="row">
      <div className="col-12 text-center">
        <a
          onClick={handleMore}
          className="btn btn-bordered-white mt-5 text-white"
        >
          {intl.translate({ id: 'Load more' })}
        </a>
      </div>
    </div>
  );
};

export default LoadMore;
