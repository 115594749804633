/* eslint-disable @typescript-eslint/no-explicit-any */
import ActionModal from 'design/Modal/ActionModal';
import useWallet from 'hooks/useWallet';
import { FC, useCallback, useEffect, useState } from 'react';
import { Royalties } from 'types/royalties';
import { User } from 'types/users-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const royaltiesEmptyDefault: Royalties = {
  blockLoyalFee: '',
  vendorFee: '',
};

type Props = {
  vendor?: Pick<User, 'allowedHosts' | 'walletAddress' | 'nickName'>;
};

const RoyaltiesForm: FC<Props> = ({ vendor }: Props) => {
  const { updateRoyalties, getRoyalties } = useWallet();
  const [formData, setFormData] = useState(royaltiesEmptyDefault);
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const setRoyalties = useCallback(async () => {
    const royalties = await getRoyalties(vendor?.walletAddress as string);
    if (royalties) {
      setFormData(royalties);
    }
  }, [getRoyalties, vendor?.walletAddress]);

  const validateNumber = (value: string) => {
    return !isNaN(+value) && +value >= 0;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...formData };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any)[event.target.name] = event.target.value;
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;

    if (!formData.blockLoyalFee || !validateNumber(formData.blockLoyalFee)) {
      setValidForm(false);
      valid = false;
    }

    if (!formData.vendorFee || !validateNumber(formData.vendorFee)) {
      setValidForm(false);
      valid = false;
    }

    if (!valid) {
      return;
    }

    setLoading(true);

    try {
      await updateRoyalties(vendor?.walletAddress as string, formData);
      setOpenSuccessModal(true);
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRoyalties();
  }, [setRoyalties]);

  return (
    <>
      <form
        className="item-form no-hover pt-0"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div className="row card mb-3">
          <div className="col-12">
            <div className="form-group mb-0">
              <input
                type="text"
                className={`form-control ${
                  !validForm &&
                  (!formData.blockLoyalFee ||
                    !validateNumber(formData.blockLoyalFee))
                    ? 'is-invalid'
                    : ''
                }`}
                name="blockLoyalFee"
                placeholder={intl.translate({ id: 'BlockLoyal Fee' })}
                required
                value={formData.blockLoyalFee}
                disabled={loading}
                onChange={handleInputChange}
                maxLength={40}
              />
              <div id="validationServer03Feedback" className="invalid-feedback">
                {intl.translate({
                  id: 'Please provide a valid fee.',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row card mb-3">
          <div className="col-12">
            <div className="form-group mb-0">
              <input
                type="text"
                className={`form-control ${
                  !validForm &&
                  (!formData.vendorFee || !validateNumber(formData.vendorFee))
                    ? 'is-invalid'
                    : ''
                }`}
                name="vendorFee"
                placeholder={intl.translate({ id: 'Vendor Fee' })}
                required
                value={formData.vendorFee}
                disabled={loading}
                onChange={handleInputChange}
              />
              <div id="validationServer03Feedback" className="invalid-feedback">
                {intl.translate({
                  id: 'Please provide a valid fee.',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button
            className="btn w-100 mt-3 mt-sm-4"
            type="submit"
            disabled={loading}
          >
            {!loading
              ? intl.translate({
                  id: 'Save',
                })
              : intl.translate({
                  id: 'Saving...',
                })}
          </button>
        </div>
      </form>
      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        onConfirm={() => null}
        description={errorMessage}
        okButtonText="OK"
        noCancelButton={true}
      />
      <ActionModal
        title={intl.translate({
          id: 'Success',
        })}
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        onConfirm={() => null}
        description="Transaction completed successfully"
        okButtonText="OK"
        noCancelButton={true}
      />
    </>
  );
};

export default RoyaltiesForm;
