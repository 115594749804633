import * as alchemy from './alchemy.config';
import * as base from './base.config';
import * as blockchain from './blockchain.config';
import * as http from './http.config';
import * as infura from './infura.config';
import * as theme from './theme.config';
import * as validation from './validation.config';

export const config = {
  base,
  http,
  alchemy,
  blockchain,
  infura,
  validation,
  theme,
};
