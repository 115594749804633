import { listNftOfVendorApi } from 'api/vendor.api';
import LoadMore from 'components/LoadMore/LoadMore';
import NftVendorCard from 'components/NftCard/NftVendorCard/NftVendorCard';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { NftModel } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledItemsDiv = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}px) {
    min-width: 1170px;
  }
`;

const StyledSection = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: 47rem;
  }
`;

const defaultEmptyNft: NftModel[] = [];

const VendorListNftView: FC = () => {
  const itemsPerPage = 16;
  const [nfts, setNfts] = useState<NftModel[]>(defaultEmptyNft);
  const [nftIndex, setNftIndex] = useState<number>(itemsPerPage);
  const [completed, setCompleted] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  const getNfts = async () => {
    setLoading(true);
    const returnNfts = await listNftOfVendorApi();
    setNfts(returnNfts);
    if (returnNfts.length > itemsPerPage) {
      setCompleted(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    getNfts();
  }, []);

  const handleMoreNfts = () => {
    setNftIndex((previousState) => previousState + itemsPerPage);
    if (nftIndex + itemsPerPage > nfts.length) {
      setCompleted(true);
    }
  };

  return (
    <StyledSection className="explore-area p-0">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="intro d-flex justify-content-between align-items-end m-0">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Vendor' })}</span>
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'Available NFTs' })}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <StyledItemsDiv className="row items">
          {loading ? (
            <div className="container text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">
                  {intl.translate({ id: 'Loading...' })}
                </span>
              </div>
            </div>
          ) : !nfts.length ? (
            <div className="container text-center">
              <h4>{intl.translate({ id: 'There are no NFTs to show' })}</h4>
            </div>
          ) : (
            nfts.slice(0, nftIndex).map((nft: NftModel, idx) => {
              return <NftVendorCard {...nft} key={`nft_${idx}`} />;
            })
          )}
        </StyledItemsDiv>
        <LoadMore completed={completed} handleMore={handleMoreNfts} />
      </div>
    </StyledSection>
  );
};

export default VendorListNftView;
