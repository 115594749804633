import logo from 'assets/logo.png';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';

const StyledDescriptionP = styled.p`
  font-weight: 600;
`;

const StyledRightsDiv = styled.div`
  font-weight: 600;
`;

const StyledNavigationA = styled.a`
  font-weight: 600 !important;
`;

const StyledLogoLink = styled(Link)`
  max-width: 80px;
`;

const Footer: FC = () => {
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <>
      <footer className="footer-area">
        {/* Footer Top */}
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 res-margin">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Logo */}
                  <StyledLogoLink to={AppRoute.Home} className="navbar-brand">
                    <img src={logo} alt="BlockLoyal logo" />
                  </StyledLogoLink>
                  {/* <StyledDescriptionP>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Quis non, fugit totam vel laboriosam vitae.
                  </StyledDescriptionP> */}
                  {/* Social Icons */}
                  <div className="social-icons d-flex">
                    <i className="" />
                    {/* {this.state.socialData.map((item, idx) => {
                    return (
                      <StyledNavigationA key={`sd_${idx}`} className={item.link} href="#">
                        <i className={item.icon} />
                        <i className={item.icon} />
                      </StyledNavigationA>
                    );
                  })} */}
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 res-margin">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">
                    {intl.translate({ id: 'Useful Links' })}
                  </h4>
                  <ul>
                    <li>
                      <Link to={AppRoute.PrivacyPolicy}>
                        <strong>Privacy Policy</strong>
                      </Link>
                    </li>
                    <li>
                      <Link to={AppRoute.Docs}>
                        <strong>Shopify App Docs</strong>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 res-margin"></div>
              <div className="col-12 col-sm-6 col-lg-3">
                {/* Footer Items */}
                <div className="footer-items">
                  {/* Footer Title */}
                  <h4 className="footer-title">
                    {intl.translate({ id: 'Subscribe Us' })}
                  </h4>
                  {/* Subscribe Form */}
                  <div className="subscribe-form d-flex align-items-center">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="info@yourmail.com"
                    />
                    <button
                      type="submit"
                      className="btn"
                      onClick={() => setOpenModal(true)}
                    >
                      <i className="icon-paper-plane" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Bottom */}
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/* Copyright Area */}
                <StyledRightsDiv className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                  {/* Copyright Left */}
                  <div className="copyright-left">
                    ©{new Date().getFullYear()}{' '}
                    {intl.translate({ id: 'BlockLoyal, All Rights Reserved.' })}
                  </div>
                  {/* Copyright Right */}
                  <div className="copyright-right">
                    {intl.translate({ id: 'Made By' })}{' '}
                    <StyledNavigationA href="https://www.spacedev.io/">
                      SpaceDev
                    </StyledNavigationA>
                  </div>
                </StyledRightsDiv>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openModal}
        setOpen={setOpenModal}
        onConfirm={() => null}
        description={intl.translate({
          id: 'Newsletters are currently unavailable.',
        })}
        okButtonText="OK"
        noCancelButton={true}
      />
    </>
  );
};

export default Footer;
