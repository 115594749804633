import { Link } from 'react-router-dom';
import { intl } from 'utilities/i18n/intl.utility';

const NoNFT = ({ backTo }: { backTo: string }) => {
  return (
    <>
      <h3 className="text-white">
        {intl.translate({ id: "There isn't a NFT created with this ID." })}
      </h3>
      <div className="justify-content-center d-flex">
        <Link
          to={backTo}
          type="button"
          className="btn btn-bordered-white btn-smaller mt-4 text-white"
        >
          {intl.translate({ id: 'Go back' })}
        </Link>
      </div>
    </>
  );
};

export default NoNFT;
