/* eslint-disable @typescript-eslint/no-explicit-any */
import { editVendorPasswordApi } from 'api/admin.api';
import ActionModal from 'design/Modal/ActionModal';
import { FC, useState } from 'react';
import { Password } from 'types/password';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const royaltiesEmptyDefault: Password = {
  password: '',
  repeatPassword: '',
};

type Props = {
  id: string;
  validatePassword: (password: string) => boolean;
};

const PasswordForm: FC<Props> = ({ id, validatePassword }: Props) => {
  const [formData, setFormData] = useState(royaltiesEmptyDefault);
  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const data = { ...formData };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as any)[event.target.name] = event.target.value;
    setFormData(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    let valid = true;

    if (
      !formData.password ||
      formData.repeatPassword !== formData.password ||
      !validatePassword(formData.password)
    ) {
      setValidForm(false);
      valid = false;
    }

    if (!valid) {
      return;
    }

    setLoading(true);

    try {
      await editVendorPasswordApi(id, { password: formData.password });
      setOpenSuccessModal(true);
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form
        className="item-form no-hover pt-0"
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
      >
        <div className="row card mb-3">
          <div className="col-12">
            <div className="form-group mb-0">
              <input
                type="password"
                className={`form-control ${
                  !validForm &&
                  (!formData.password || !validatePassword(formData.password))
                    ? 'is-invalid'
                    : ''
                }`}
                name="password"
                placeholder={intl.translate({ id: 'Password' })}
                required
                value={formData.password}
                disabled={loading}
                onChange={handleInputChange}
                maxLength={40}
              />
              <div id="validationServer03Feedback" className="invalid-feedback">
                {intl.translate({
                  id: 'Please provide a valid Password. It must contain one uppercase letter, one special character and 8 characters length.',
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="row card mb-3">
          <div className="col-12">
            <div className="form-group mb-0">
              <input
                type="password"
                className={`form-control ${
                  formData.repeatPassword &&
                  formData.repeatPassword !== formData.password
                    ? 'is-invalid'
                    : ''
                }`}
                name="repeatPassword"
                placeholder={intl.translate({ id: 'Repeat Password' })}
                required
                value={formData.repeatPassword}
                disabled={loading}
                onChange={handleInputChange}
              />
              <div id="validationServer03Feedback" className="invalid-feedback">
                {intl.translate({
                  id: "Password doesn't match",
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <button
            className="btn w-100 mt-3 mt-sm-4"
            type="submit"
            disabled={loading}
          >
            {!loading
              ? intl.translate({
                  id: 'Save',
                })
              : intl.translate({
                  id: 'Saving...',
                })}
          </button>
        </div>
      </form>
      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        onConfirm={() => null}
        description={errorMessage}
        okButtonText="OK"
        noCancelButton={true}
      />
      <ActionModal
        title={intl.translate({
          id: 'Success',
        })}
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        onConfirm={() => null}
        description={intl.translate({
          id: 'Password changed successfully',
        })}
        okButtonText="OK"
        noCancelButton={true}
      />
    </>
  );
};

export default PasswordForm;
