import { createLoyaltyProgram } from 'api/vendor.api';
import BackButton from 'components/Common/BackButton/BackButton';
import ActionModal from 'design/Modal/ActionModal';
import { AppRoute } from 'enums/app-route.enum';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { Level, LoyaltyProgram } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledNumberInput = styled.input`
  &::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    display: none;
  }
`;

const formDataLevelEmptyDefault: Level = {
  ecommerceCupon: '',
  cuponDescription: '',
  requiredPointsGetLevel: undefined,
  pointsPerDollar: undefined,
  exclusivePageCode: '',
};
const formDataLevelsDefault: Level[] = [{ ...formDataLevelEmptyDefault }];
const formDataDefault: LoyaltyProgram = {
  name: '',
  levels: formDataLevelsDefault.slice(),
};

const invalidNumberInputSymbols = ['e', 'E', '+', '-', '.', ','];

const CreateLoyaltyProgramView: FC = () => {
  const [formData, setFormData] = useState({ ...formDataDefault });
  const [formDataLevels, setFormDataLevels] = useState(
    [{ ...formDataLevelEmptyDefault }].slice(),
  );

  const [validForm, setValidForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { nftId } = useParams();

  const handleNameInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.persist();
    setFormData((values) => ({
      ...values,
      name: event.target.value,
    }));
  };

  const handleInputLevelChange = (
    index: number,
    event:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const data = [...formDataLevels];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data[index] as any)[event.target.name] = event.target.value;
    setFormDataLevels(data);
  };

  const addLevels = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    setFormDataLevels([...formDataLevels, { ...formDataLevelEmptyDefault }]);
  };

  const deleteLevel = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event.preventDefault();
    const data = [...formDataLevels];
    if (data.length === 1) {
      return;
    }
    data.splice(index, 1);
    setFormDataLevels(data);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let valid = true;

    if (!formData.name) {
      setValidForm(false);
      valid = false;
    }

    formDataLevels.forEach((input, index) => {
      if (!input.ecommerceCupon) {
        setValidForm(false);
        valid = false;
      }

      if (!input.cuponDescription) {
        setValidForm(false);
        valid = false;
      }

      if (
        !input.requiredPointsGetLevel ||
        input.requiredPointsGetLevel <= 0 ||
        input.requiredPointsGetLevel > 9999999
      ) {
        setFormDataLevels((previousValue) =>
          previousValue.map((level, levelIndex) => {
            if (levelIndex === index) {
              level.requiredPointsGetLevel = undefined;
            }
            return level;
          }),
        );
        setValidForm(false);
        valid = false;
      }

      if (
        !input.pointsPerDollar ||
        input.pointsPerDollar <= 0 ||
        input.pointsPerDollar > 9999999
      ) {
        setFormDataLevels((previousValue) =>
          previousValue.map((level, levelIndex) => {
            if (levelIndex === index) {
              level.pointsPerDollar = undefined;
            }
            return level;
          }),
        );
        setValidForm(false);
        valid = false;
      }

      if (!input.exclusivePageCode) {
        setValidForm(false);
        valid = false;
      }
    });

    if (!valid) {
      return;
    }

    setLoading(true);
    formData.levels = formDataLevels;

    const nftIdNotNull = nftId ? nftId : '';

    try {
      await createLoyaltyProgram(nftIdNotNull, formData);

      setFormData({ ...formDataDefault });
      setFormDataLevels([{ ...formDataLevelEmptyDefault }].slice());

      navigate(AppRoute.Home, { replace: true });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setErrorMessage(err.message);
      setOpenErrorModal(true);
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    invalidNumberInputSymbols.includes(event.key) && event.preventDefault();
  };

  const handleOnPaste = (
    index: number,
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    try {
      const cleanString = event.clipboardData
        .getData('Text')
        .replace(/\D/g, '');
      const numericValue = parseInt(cleanString);

      const data = [...formDataLevels];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (data[index] as any)[event.currentTarget.name] = numericValue;
      setFormDataLevels(data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
    event.preventDefault();
  };

  return (
    <section className="author-area p-0">
      <div className="container">
        <BackButton />
        <div className="row justify-content-between">
          <div className="col-12 ml-md-auto">
            {/* Intro */}
            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Loyalty Program' })}</span>
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'Create' })}
                </h3>
              </div>
            </div>
            {/* Item Form */}
            <form
              className="item-form no-hover pt-0"
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="row card mb-3">
                <div className="col-12">
                  <div className="form-group mb-0">
                    <input
                      type="text"
                      className={`form-control ${
                        !validForm && !formData.name ? 'is-invalid' : ''
                      }`}
                      name="name"
                      placeholder={intl.translate({
                        id: 'Loyalty Program Name',
                      })}
                      required
                      value={formData.name}
                      disabled={loading}
                      onChange={handleNameInputChange}
                      maxLength={30}
                    />
                    <div
                      id="validationServer03Feedback"
                      className="invalid-feedback"
                    >
                      {intl.translate({
                        id: 'Please provide a Loyalty Program Name.',
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {formDataLevels.map((input, index) => {
                return (
                  <div key={index} className="card my-3">
                    <div className="col-12 d-flex justify-content-between">
                      <h4 className="ml-3 my-3">Level {index + 1}</h4>
                      {formDataLevels.length > 1 ? (
                        <button
                          style={{ backgroundImage: 'none' }}
                          className="btn btn-danger mb-3 mb-sm-4"
                          disabled={loading}
                          onClick={(event) => deleteLevel(index, event)}
                        >
                          {intl.translate({ id: 'Remove level' })}
                        </button>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group mt-3">
                          <input
                            type="text"
                            className={`form-control ${
                              !validForm &&
                              !formDataLevels[index].ecommerceCupon
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder={intl.translate({
                              id: 'Ecommerce Coupon',
                            })}
                            required
                            name="ecommerceCupon"
                            value={input.ecommerceCupon}
                            onChange={(event) =>
                              handleInputLevelChange(index, event)
                            }
                            disabled={loading}
                            maxLength={30}
                          />
                          <div
                            id="validationServer03Feedback"
                            className="invalid-feedback"
                          >
                            {intl.translate({
                              id: 'Please provide an Ecommerce Coupon.',
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group mt-3">
                          <input
                            type="text"
                            className={`form-control ${
                              !validForm &&
                              !formDataLevels[index].exclusivePageCode
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder={intl.translate({
                              id: 'Exclusive Page Code',
                            })}
                            required
                            name="exclusivePageCode"
                            value={input.exclusivePageCode}
                            onChange={(event) =>
                              handleInputLevelChange(index, event)
                            }
                            disabled={loading}
                            maxLength={30}
                          />
                          <div
                            id="validationServer03Feedback"
                            className="invalid-feedback"
                          >
                            {intl.translate({
                              id: 'Please provide an Exclusive Page Code.',
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group mt-3">
                          <StyledNumberInput
                            className={`form-control ${
                              !validForm &&
                              !formDataLevels[index].requiredPointsGetLevel
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder={intl.translate({
                              id: 'Required Points',
                            })}
                            required
                            type="number"
                            name="requiredPointsGetLevel"
                            value={input.requiredPointsGetLevel}
                            onChange={(event) =>
                              handleInputLevelChange(index, event)
                            }
                            onKeyDown={handleKeyDown}
                            onPaste={(event) => handleOnPaste(index, event)}
                            min={0}
                            max={9999999}
                            disabled={loading}
                          />
                          <div
                            id="validationServer03Feedback"
                            className="invalid-feedback"
                          >
                            {intl.translate({
                              id: 'Please provide valid Required Points value.',
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group mt-3">
                          <StyledNumberInput
                            className={`form-control ${
                              !validForm &&
                              !formDataLevels[index].pointsPerDollar
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder={intl.translate({
                              id: 'Points Per Dollar',
                            })}
                            required
                            type="number"
                            name="pointsPerDollar"
                            value={input.pointsPerDollar}
                            onChange={(event) =>
                              handleInputLevelChange(index, event)
                            }
                            onKeyDown={handleKeyDown}
                            onPaste={(event) => handleOnPaste(index, event)}
                            min={0}
                            max={9999999}
                            disabled={loading}
                          />
                          <div
                            id="validationServer03Feedback"
                            className="invalid-feedback"
                          >
                            {intl.translate({
                              id: 'Please provide valid Points Per Dollar value.',
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group mt-3">
                          <textarea
                            className={`form-control ${
                              !validForm &&
                              !formDataLevels[index].cuponDescription
                                ? 'is-invalid'
                                : ''
                            }`}
                            placeholder={intl.translate({
                              id: 'Coupon Description',
                            })}
                            cols={30}
                            rows={3}
                            name="cuponDescription"
                            value={input.cuponDescription}
                            onChange={(event) =>
                              handleInputLevelChange(index, event)
                            }
                            maxLength={240}
                            disabled={loading}
                          />
                          <div
                            id="validationServer03Feedback"
                            className="invalid-feedback"
                          >
                            {intl.translate({ id: 'Coupon Description' })}
                          </div>
                        </div>
                      </div>
                      {formDataLevels.length === index + 1 && (
                        <div className="col-12">
                          <button
                            style={{ backgroundImage: 'none' }}
                            className="btn btn-primary mt-3 mt-sm-4 float-right"
                            disabled={loading}
                            onClick={addLevels}
                          >
                            {intl.translate({ id: 'Add new level' })}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="col-12">
                <button
                  className="btn w-100 mt-3 mt-sm-4"
                  type="submit"
                  disabled={loading}
                >
                  {!loading
                    ? intl.translate({
                        id: 'Create',
                      })
                    : intl.translate({
                        id: 'Wait...',
                      })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        onConfirm={() => null}
        description={errorMessage}
        okButtonText="OK"
        noCancelButton={true}
      />
    </section>
  );
};

export default CreateLoyaltyProgramView;
