import {
  useAccountModal,
  useAddRecentTransaction,
} from '@rainbow-me/rainbowkit';
import { createNftApi, updateNftModelToMintedApi } from 'api/admin.api';
import { getNftMetadataByTokenId, getNftsByAddress } from 'api/customer.api';
import { getTokenIdsForVendorNFT } from 'api/vendor.api';
import { authProvider } from 'components/Auth/Auth.provider';
import {
  blockLoyalContractAddress,
  blockLoyalMarketplaceContractAddress,
} from 'config/blockchain.config';
import { ethers, Event } from 'ethers';
import { useCallback, useState } from 'react';
import { useBranchState } from 'react-branch-provider';
import { NftMetadata } from 'types/nft-props';
import { Royalties } from 'types/royalties';
import { logger } from 'utilities/logger/Logger';
import { useAccount, useBalance, useSigner } from 'wagmi';
import BlockLoyalContract from '../contract/BlockLoyalFactory.json';
import BlockLoyalMarketplaceContract from '../contract/BlockLoyalMarketplace.json';

const useWallet = () => {
  const { user } = useBranchState(authProvider);
  const { data: signer } = useSigner({
    onSuccess(data) {
      return data;
    },
  });
  const { address } = useAccount();
  const { data, error } = useBalance({
    address,
  });
  const [isMinting, setIsMinting] = useState(false);
  const [errorOpen, setErrorOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const addRecentTransaction = useAddRecentTransaction();
  const { openAccountModal } = useAccountModal();

  const mintNftBatch = useCallback(
    async (id: string, address: string, amount: number, nftModelId: string) => {
      try {
        setIsMinting(true);
        if (signer) {
          const blockLoyalContract = new ethers.Contract(
            blockLoyalContractAddress,
            BlockLoyalContract.abi,
            signer,
          );
          const maxGasLimit = 25000000;
          const estimatedGas = 100000 + 35000 * amount;
          const tx = await blockLoyalContract.mintBatch(amount, address, {
            gasLimit: estimatedGas > maxGasLimit ? maxGasLimit : estimatedGas,
          });

          if (tx?.hash) {
            addRecentTransaction({
              hash: tx?.hash,
              description: 'BlockLoyal NFT Mint',
            });
            openAccountModal?.();
          }
          const result = await tx.wait();

          let min = Infinity;
          let max = -Infinity;
          if (result?.events?.length) {
            result.events.forEach(async (event: Event) => {
              if (event.event === 'Transfer') {
                min = Math.min(min, +event.args?.tokenId);
                max = Math.max(max, +event.args?.tokenId);
                await createNftApi({
                  nftModel: nftModelId,
                  tokenId: +event.args?.tokenId,
                });
              }
            });
            await updateNftModelToMintedApi(nftModelId, {
              tokenRangeFrom: min,
              tokenRangeTo: max,
            });
          }

          logger.info('Minted NFTs succesfully');
        } else {
          throw new Error('No signer to execute transaction');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (err.code === 'ACTION_REJECTED') {
          setErrorMessage('User rejected transaction');
        } else {
          setErrorMessage(err.message);
        }
        setErrorOpen(true);
        throw new Error(err.message);
      } finally {
        setIsMinting(false);
      }
    },
    [signer, addRecentTransaction, openAccountModal],
  );

  const transferNftBatch = useCallback(
    async (
      vendorAddress: string,
      nftModelId: string,
      to: string[],
      amount: number[],
    ) => {
      try {
        setIsMinting(true);
        if (to.length !== amount.length) {
          throw new Error('Address and Amount arrays are not equal');
        }

        if (to.length > 700) {
          throw new Error('Address and Amount arrays are not equal');
        }

        if (signer) {
          const blockLoyalContract = new ethers.Contract(
            blockLoyalContractAddress,
            BlockLoyalContract.abi,
            signer,
          );
          const maxAmount = await blockLoyalContract._maxAmount();
          if (to.length > +maxAmount) {
            throw new Error('Exceeded the maximum amount of transfers');
          }

          const vendorTokenIds = await getTokenIdsForVendorNFT(
            vendorAddress,
            nftModelId,
          );

          const totalAmount = amount.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
          );
          if (totalAmount > vendorTokenIds.amount) {
            throw new Error('Exceeded the amount of tokens owned');
          }

          const maxGasLimit = 25000000;
          const estimatedGas = 100000 + 40000 * totalAmount;
          const tx = await blockLoyalContract.transferBatch(
            to,
            amount,
            vendorTokenIds.ids,
            {
              gasLimit: estimatedGas > maxGasLimit ? maxGasLimit : estimatedGas,
            },
          );

          if (tx?.hash) {
            addRecentTransaction({
              hash: tx?.hash,
              description: 'BlockLoyal Bulk Transfer',
            });
            openAccountModal?.();
          }
          await tx.wait();

          logger.info('Transfered NFTs succesfully');
        } else {
          throw new Error('No signer to execute transaction');
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        let errorMessage = err.message;
        if (err.code === 'ACTION_REJECTED') {
          errorMessage = 'User rejected transaction';
        }
        setErrorMessage(errorMessage);
        setErrorOpen(true);
        throw new Error(errorMessage);
      } finally {
        setIsMinting(false);
      }
    },
    [signer, addRecentTransaction, openAccountModal],
  );

  const updateRoyalties = async (
    vendorAddress: string,
    royalties: Royalties,
  ) => {
    try {
      if (signer) {
        const marketplaceContract = new ethers.Contract(
          blockLoyalMarketplaceContractAddress,
          BlockLoyalMarketplaceContract.abi,
          signer,
        );
        const vendorFeeParsed = ethers.utils.parseEther(
          royalties.vendorFee.toString(),
        );
        const blockLoyalFeeParsed = ethers.utils.parseEther(
          royalties.blockLoyalFee.toString(),
        );

        const tx = await marketplaceContract.updateVendorFees(
          vendorAddress,
          vendorFeeParsed,
          blockLoyalFeeParsed,
        );

        if (tx?.hash) {
          addRecentTransaction({
            hash: tx?.hash,
            description: 'Set Royalties',
          });
          openAccountModal?.();
        }
        await tx.wait();
      } else {
        throw new Error('No signer to execute transaction');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      let errorMessage = err.message;
      if (err.code === 'ACTION_REJECTED') {
        errorMessage = 'User rejected transaction';
      }
      setErrorMessage(errorMessage);
      setErrorOpen(true);
      throw new Error(errorMessage);
    }
  };

  const updateVendorAddressForRoyalties = async (
    oldVendorAddress: string,
    newVendorAddress: string,
  ) => {
    try {
      if (signer) {
        const marketplaceContract = new ethers.Contract(
          blockLoyalMarketplaceContractAddress,
          BlockLoyalMarketplaceContract.abi,
          signer,
        );

        const tx = await marketplaceContract.updateVendorAddress(
          oldVendorAddress,
          newVendorAddress,
        );

        if (tx?.hash) {
          addRecentTransaction({
            hash: tx?.hash,
            description: 'Update Vendor Address',
          });
          openAccountModal?.();
        }
        await tx.wait();
      } else {
        throw new Error('No signer to execute transaction');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      let errorMessage = err.message;
      if (err.code === 'ACTION_REJECTED') {
        errorMessage = 'User rejected transaction';
      }
      setErrorMessage(errorMessage);
      setErrorOpen(true);
      throw new Error(errorMessage);
    }
  };

  const getRoyalties = useCallback(
    async (vendorAddress: string): Promise<Royalties | null> => {
      try {
        if (signer) {
          const marketplaceContract = new ethers.Contract(
            blockLoyalMarketplaceContractAddress,
            BlockLoyalMarketplaceContract.abi,
            signer,
          );
          const royalties = await marketplaceContract.getVendorFees(
            vendorAddress,
          );
          return {
            blockLoyalFee: ethers.utils.formatEther(royalties.blockLoyalFee),
            vendorFee: ethers.utils.formatEther(royalties.vendorFee),
          };
        }
        return null;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setErrorMessage(err.message);
        setErrorOpen(true);
        throw new Error(err.message);
      }
    },
    [signer],
  );

  const getNftsForOwner = useCallback(async (): Promise<NftMetadata[]> => {
    try {
      const address = user?.walletAddress;

      if (address) {
        const nfts = await getNftsByAddress(address);

        return nfts;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }

    return [];
  }, [user?.walletAddress]);

  const getNftMetadata = useCallback(async (tokenId: number) => {
    try {
      const nft = await getNftMetadataByTokenId(tokenId);

      return nft;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      logger.error(err);
    }
  }, []);

  const getUserBalance = useCallback(() => {
    if (error) {
      return error;
    }
    return data?.formatted ? +data?.formatted : 0;
  }, [data, error]);

  return {
    getNftsForOwner,
    getNftMetadata,
    mintNftBatch,
    updateRoyalties,
    updateVendorAddressForRoyalties,
    getRoyalties,
    isMinting,
    errorMessage,
    errorOpen,
    setErrorOpen,
    transferNftBatch,
    getUserBalance,
  };
};

export default useWallet;
