import styled from 'styled-components';
import { Nft } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';

const StyledContainerDiv = styled.div``;

const StyledNftImg = styled.img`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  height: fit-content;
`;

const StyledSpan = styled.span`
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0px;
  margin: 0px;
  padding: 0px;
  position: absolute;
  inset: 0px;
`;

const StyledImgContainerDiv = styled.div`
  height: 0px;
  padding-bottom: 66.6667%;
  position: relative;
`;

const NftCollectionCard = ({ nft }: { nft: Nft }) => {
  return (
    <StyledContainerDiv
      className="swiper-slide col-12 col-sm-6 col-lg-3 item"
      title={nft.title}
    >
      <div className="card">
        <StyledImgContainerDiv className="image-over">
          <StyledSpan>
            <StyledNftImg className="card-img-top" src={nft.image} alt="" />
          </StyledSpan>
        </StyledImgContainerDiv>
        {/* Card Caption */}
        <div className="card-caption col-12 p-0">
          <div className="card-body">
            <div className="countdown-times mb-3">
              <h5 className="mb-0">{nft.title}</h5>
              <div className="card-bottom d-flex justify-content-between mt-2">
                <span>
                  {intl.translate({ id: 'Copies' })}: {nft.tokenCopies}
                </span>
              </div>
              <p className="text-center mt-3">
                {nft.vendorOwner.nickName || nft.vendorOwner.walletAddress}
              </p>
            </div>
          </div>
        </div>
      </div>
    </StyledContainerDiv>
  );
};

export default NftCollectionCard;
