/* eslint-disable @typescript-eslint/no-explicit-any */
import csvTemplate from 'assets/CSV-Transfer-Template.csv';
import BackButton from 'components/Common/BackButton/BackButton';
import CsvTransferForm from 'components/TransferNft/MultipleTransfer/CsvTransferForm/CsvTransferForm';
import ManualTransferForm from 'components/TransferNft/MultipleTransfer/ManualTransferForm/ManualTransferForm';
import ActionModal from 'design/Modal/ActionModal';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { intl } from 'utilities/i18n/intl.utility';
import { useAccount } from 'wagmi';

const StyledSection = styled.section`
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    min-width: 1170px;
  }
`;

const TransferMultipleView: FC = () => {
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const { address } = useAccount();

  const { id } = useParams();

  return (
    <StyledSection className="author-area p-0">
      <div className="container">
        <BackButton />
        <div className="row justify-content-between">
          <div className="col-12">
            {/* Intro */}
            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
              <div className="intro-content">
                <span>{intl.translate({ id: 'Transfer Multiple' })}</span>
                <h3 className="mt-3 mb-0">{intl.translate({ id: 'Csv' })}</h3>
              </div>
            </div>
            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
              <p>
                {intl.translate(
                  {
                    id: 'Follow the <a>suggested template</a> for submitting a .csv file.',
                  },
                  {
                    a: (label) => (
                      <a
                        href={csvTemplate}
                        download="TransferTemplateCSV"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {label}
                      </a>
                    ),
                  },
                )}
              </p>
            </div>

            {/* Item Form */}
            <CsvTransferForm
              setErrorMessage={setErrorMessage}
              setOpenErrorModal={setOpenErrorModal}
              setOpenSuccessModal={setOpenSuccessModal}
              nftId={id}
              vendorAddress={address}
            />
          </div>
          <div className="col-12 mt-5">
            {/* Intro */}
            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5">
              <div className="intro-content">
                <h3 className="mt-3 mb-0">
                  {intl.translate({ id: 'Manual' })}
                </h3>
              </div>
            </div>
            {/* Item Form */}
            <ManualTransferForm
              setErrorMessage={setErrorMessage}
              setOpenErrorModal={setOpenErrorModal}
              setOpenSuccessModal={setOpenSuccessModal}
              nftId={id}
              vendorAddress={address}
            />
          </div>
        </div>
      </div>
      <ActionModal
        title={intl.translate({
          id: 'Error',
        })}
        open={openErrorModal}
        setOpen={setOpenErrorModal}
        onConfirm={() => null}
        description={errorMessage}
        okButtonText="OK"
        noCancelButton={true}
      />
      <ActionModal
        title={intl.translate({
          id: 'Success',
        })}
        open={openSuccessModal}
        setOpen={setOpenSuccessModal}
        onConfirm={() => null}
        description="Transaction completed successfully"
        okButtonText="OK"
        noCancelButton={true}
      />
    </StyledSection>
  );
};

export default TransferMultipleView;
