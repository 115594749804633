import { FC } from 'react';
import styled from 'styled-components';
import { Align } from 'layout';
import VendorForm from 'components/VendorForm/VendorForm';

const StyledAlign = styled(Align)`
  height: 100%;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 700px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}px) {
    width: 1020px;
  }
`;

const AdminCreateVendorView: FC = () => {
  return (
    <StyledAlign v-center h-center>
      <VendorForm></VendorForm>
    </StyledAlign>
  );
};

export default AdminCreateVendorView;
