import { getNftMetadataByTokenId } from 'api/customer.api';
import BackButton from 'components/Common/BackButton/BackButton';
import Loading from 'components/Common/Loading/Loading';
import NoNFT from 'components/NoNFT/NoNFT';
import { AppRoute } from 'enums/app-route.enum';
import { Align } from 'layout';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NftMetadata } from 'types/nft-props';
import { intl } from 'utilities/i18n/intl.utility';
import { logger } from 'utilities/logger/Logger';

const StyledAlign = styled(Align)`
  height: 100%;
  width: 100vw;
`;

const CustomerNFTDetailedView: FC = () => {
  const { id } = useParams();
  const [nft, setNft] = useState<NftMetadata>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getNft = async () => {
      try {
        if (id) {
          setLoading(true);
          const nft = await getNftMetadataByTokenId(+id);
          if (nft) {
            setNft(nft);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        logger.error(error);
      } finally {
        setLoading(false);
      }
    };

    getNft();
  }, [id]);

  if (loading) {
    return <Loading />;
  }

  if (!nft) {
    return <NoNFT backTo={`${AppRoute.Customer}`} />;
  }

  return (
    <StyledAlign v-center h-center>
      <section className="item-details-area pt-3">
        <div className="container">
          <BackButton />
          <div className="row justify-content-between mt-5">
            {/* 1st Row */}
            <div className="col-12 col-lg-5">
              <div className="item-info">
                <div className="item-thumb text-center">
                  <img src={nft.image} alt="" />
                </div>
              </div>
            </div>
            {/* 2nd Row */}
            <div className="col-12 col-lg-6">
              {/* NFT Content */}
              <div className="content mt-5 mt-lg-0">
                <h3 className="m-0">{nft.title}</h3>
                <p>{nft.description}</p>
                <div className="item-info-list mt-4">
                  <ul className="list-unstyled">
                    <li className="price d-flex justify-content-between">
                      <span>{intl.translate({ id: 'Number of copies:' })}</span>
                      <span>{nft.tokenCopies}</span>
                    </li>
                    <li className="price d-flex justify-content-between">
                      <span>{intl.translate({ id: 'Created by:' })}</span>
                      <span>
                        {nft.vendorOwner.nickName} -{' '}
                        {`${nft.vendorOwner?.walletAddress?.substring(
                          0,
                          4,
                        )}...${nft.vendorOwner?.walletAddress?.substring(
                          38,
                          nft.vendorOwner?.walletAddress?.length,
                        )}`}
                      </span>
                    </li>
                  </ul>
                </div>
                {/* LP Info */}
                <p className="font-weight-bold text-white mb-1">
                  {intl.translate({ id: 'Loyalty Program' })}
                </p>
                {nft.attributes ? (
                  <>
                    <div className="item-info-list mt-4">
                      <ul className="list-unstyled">
                        <li className="price d-flex justify-content-between">
                          <span>{intl.translate({ id: 'Level' })}:</span>
                          <div className="progress w-50">
                            <div
                              className="progress-bar"
                              style={{
                                width: `${
                                  (nft.attributes.currentLevel * 100) /
                                  nft.attributes.maxLevel
                                }%`,
                              }}
                              aria-valuemax={100}
                              aria-valuenow={
                                (nft.attributes.currentLevel * 100) /
                                nft.attributes.maxLevel
                              }
                            >
                              <span>{`${
                                nft.attributes.currentLevel
                              } ${intl.translate({
                                id: 'of',
                              })} ${nft.attributes.maxLevel}`}</span>
                            </div>
                          </div>
                        </li>
                        <li className="price d-flex justify-content-between">
                          <span>
                            {intl.translate({ id: 'Current points:' })}
                          </span>
                          {nft.attributes.pointsRequiredForNextLevel ? (
                            <div className="progress w-50">
                              <div
                                className="progress-bar"
                                style={{
                                  width: `${
                                    nft.loyaltyPointsGenerated
                                      ? (nft.loyaltyPointsGenerated * 100) /
                                        nft.attributes
                                          .pointsRequiredForNextLevel
                                      : 100
                                  }%`,
                                }}
                                aria-valuemax={100}
                                aria-valuenow={
                                  (nft.loyaltyPointsGenerated * 100) /
                                  nft.attributes.pointsRequiredForNextLevel
                                }
                              >
                                {nft.loyaltyPointsGenerated}
                              </div>
                            </div>
                          ) : (
                            <div className="progress w-50">
                              <div
                                className="progress-bar"
                                style={{
                                  width: `100%`,
                                }}
                                aria-valuemax={100}
                                aria-valuenow={100}
                              >
                                <span>{nft.loyaltyPointsGenerated}</span>
                              </div>
                            </div>
                          )}
                        </li>
                        <li className="price d-flex justify-content-between">
                          <span>
                            {intl.translate({
                              id: 'Points required for next level:',
                            })}
                          </span>
                          <span>
                            {`${
                              nft.attributes.pointsRequiredForNextLevel
                                ? nft.attributes.pointsRequiredForNextLevel
                                : '-'
                            }`}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <span>
                    {intl.translate({ id: 'No Loyalty Prorgram created' })}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </StyledAlign>
  );
};

export default CustomerNFTDetailedView;
